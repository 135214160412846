import { useWebsiteLocale } from '@wbk/hooks';
import { Button, ShareModal } from '@wbk/ui';
import { Link } from 'react-router-dom';
import { EventItemLocation } from '@wbk/contentful';
import { ContentfulMedia } from '@wbk/contentful';
import StreamIncludedBar from '../stream/StreamIncludedBar';
import ZoneIncludedBar from '../zone/ZoneIncludedBar';
import MobileStickyBuy from './buttons/MobileStickyBuy';
import type { ContentfulEvent, Experience } from '@wbk/contentful/api';

type Props = {
  event: ContentfulEvent;
};

const EventTitleDetails = ({ event }: Props) => {
  const { shortLang } = useWebsiteLocale();
  const hyProps = (event as Experience).hyProperties;
  const city = hyProps
    ? hyProps[`city_${shortLang}` as 'city'] || hyProps.city
    : event.location?.city;

  return (
    <>
      <div className='flex flex-wrap items-center gap-1 text-sm'>
        <EventItemLocation location={{ ...event.location, city }} />
        {!hyProps && event?.zone && (
          <>
            <span className='bg-text/60 mx-0.5 h-1 w-1 rounded-full' />
            <Button
              as={Link}
              to={`/${shortLang}/zones/${event.zone?.slug}`}
              className='px-1 py-0.5 underline'
              theme='white'
              shape='text'
            >
              {event.zone?.title}
            </Button>
          </>
        )}
        {event.isStreamingEvent && (
          <>
            <span className='bg-text/60 mx-0.5 h-1 w-1 rounded-full' />
            <StreamIncludedBar event={event} />
          </>
        )}
        {event.zoneEntryIncluded && (
          <>
            <span className='bg-text/60 mx-0.5 h-1 w-1 rounded-full' />
            <ZoneIncludedBar event={event} />
          </>
        )}
      </div>

      <div className='flex items-center justify-between'>
        <div className='xs:flex-row xs:items-center flex grow flex-col items-start justify-between'>
          <h1 className='text-start text-xl sm:text-3xl'>{event.title}</h1>{' '}
          {event?.eventLogo && (
            <ContentfulMedia
              className='xs:m-0 mx-auto my-4 max-h-8 w-auto object-cover px-4'
              media={event?.eventLogo}
              placeholder
            />
          )}
        </div>

        <ShareModal
          data={{
            title: event?.title,
            text: event?.title,
          }}
          poster={event.image11.url}
          className='hidden md:flex'
        />
      </div>

      <MobileStickyBuy event={event} />
    </>
  );
};

export default EventTitleDetails;
