import React from 'react';
import { RouteObject, redirect } from 'react-router-dom';
import Error404 from '@/components/error/404';
import AppBookingsPage from '@/pages/app/bookings';

const EventBooking = React.lazy(() => import('@/pages/events/slug/book'));
const ExperienceBooking = React.lazy(() => import('@/pages/experiences/slug/book'));
const ShowsBooking = React.lazy(() => import('@/pages/shows/slug/book'));
const RestaurantsBooking = React.lazy(() => import('@/pages/restaurants/slug/book'));
const ZoneBooking = React.lazy(() => import('@/pages/zone/slug/book'));
const PackageBooking = React.lazy(() => import('@/pages/packages/book'));
const AuctionBooking = React.lazy(() => import('@/pages/auction/slug'));
const SeasonBooking = React.lazy(() => import('@/pages/seasons/slug/book'));

export const eventBookingRoutes: RouteObject[] = [
  {
    path: 'event/:slug/book',
    errorElement: <Error404 />,
    element: <EventBooking />,
  },
  {
    path: 'events/:slug/book',
    errorElement: <Error404 />,
    element: <EventBooking />,
  },
  {
    path: 'experience/:slug/book',
    errorElement: <Error404 />,
    element: <ExperienceBooking />,
  },
  {
    path: 'experiences/:slug/book',
    errorElement: <Error404 />,
    element: <ExperienceBooking />,
  },
  {
    path: 'show/:slug/book',
    errorElement: <Error404 />,
    element: <ShowsBooking />,
  },
  {
    path: 'shows/:slug/book',
    errorElement: <Error404 />,
    element: <ShowsBooking />,
  },
  {
    path: 'restaurant/:slug/book',
    errorElement: <Error404 />,
    element: <RestaurantsBooking />,
  },
  {
    path: 'restaurants/:slug/book',
    errorElement: <Error404 />,
    element: <RestaurantsBooking />,
  },
  {
    path: 'package/:slug/book',
    errorElement: <Error404 />,
    element: <PackageBooking />,
  },
  {
    path: 'packages/:slug/book',
    errorElement: <Error404 />,
    element: <PackageBooking />,
  },
  {
    path: 'zones/:slug/book',
    errorElement: <Error404 />,
    element: <ZoneBooking />,
  },
  {
    path: 'zone/:slug/book',
    errorElement: <Error404 />,
    element: <ZoneBooking />,
  },
  {
    path: 'zones/:slug/book',
    errorElement: <Error404 />,
    element: <ZoneBooking />,
  },
  {
    path: 'seasons/:slug/book',
    errorElement: <Error404 />,
    element: <SeasonBooking />,
  },
  {
    path: 'app/bookings',
    errorElement: <Error404 />,
    element: <AppBookingsPage />,
  },
];

export const auctionBookingRoutes: RouteObject[] = [
  {
    path: 'auction/:slug',
    errorElement: <Error404 />,
    element: <AuctionBooking />,
  },
  {
    path: 'auctions/:slug',
    errorElement: <Error404 />,
    element: <AuctionBooking />,
  },
  {
    path: 'auction/:slug/book',
    loader: ({ params }) => redirect(`/auction/${params.slug}`),
  },
  {
    path: 'auctions/:slug/book',
    loader: ({ params }) => redirect(`/auction/${params.slug}`),
  },
];
