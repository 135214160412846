import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';
import { DEFAULT_LOGOS, TABBY_LOGO } from '@/constants/paymentMethods';

type Props = {
  grayish?: boolean;
  onlyLogos?: boolean;
  withTabby?: boolean;
  className?: string;
};

const PaymentMethods = ({ grayish, onlyLogos, withTabby, className = '' }: Props) => {
  const { t } = useTranslation('booking');
  const LOGOS = withTabby ? DEFAULT_LOGOS.concat(TABBY_LOGO) : DEFAULT_LOGOS;

  if (onlyLogos) {
    return (
      <div className={twMerge('flex items-center gap-4', className)}>
        {LOGOS.map((logo, i) => (
          <div key={i} className={`${grayish ? 'opacity-50 grayscale' : ''}`}>
            <img src={logo.src} width={logo.width} height={logo.height} alt={logo.alt} />
          </div>
        ))}
      </div>
    );
  }
  return (
    <div
      className={twMerge(
        'flex flex-wrap items-center justify-center',
        grayish ? '' : 'rounded-lg border border-t-0 border-white/20',
        className
      )}
    >
      {!grayish && (
        <div className='flex w-full items-center justify-between'>
          <div className='mt-0 h-5 w-full border-t border-white/20 ltr:rounded-l-lg rtl:rounded-r-lg'></div>
          <p className='-mt-4 shrink-0 grow px-2 text-center text-xs text-zinc-400'>
            {t('booking:available_pay_methods')}
          </p>
          <div className='mt-0 h-5 w-full border-t border-white/20 ltr:rounded-r-lg rtl:rounded-l-lg'></div>
        </div>
      )}
      <div className='-mt-2 flex w-full flex-wrap items-center justify-center gap-4 px-1 pb-1'>
        {LOGOS.map((logo, i) => (
          <div key={i} className={`relative my-1 ${grayish ? 'opacity-50 grayscale' : ''}`}>
            <img src={logo.src} width={logo.width} height={logo.height} alt={logo.alt} />
          </div>
        ))}
      </div>
    </div>
  );
};
export default PaymentMethods;
