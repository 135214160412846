import { useMemo } from 'react';
import { Experience } from '@wbk/contentful/api';
import { useWebsiteLocale } from '@wbk/hooks';
import { useTranslation } from 'react-i18next';
import { CollapsableText } from '@wbk/contentful';

type Props = {
  experience: Experience;
};

type ExperienceItinerary = Exclude<Experience['hyProperties'], undefined>['itinerary'][number];

const Itinerary = ({ experience }: Props) => {
  const { shortLang } = useWebsiteLocale();
  const { t } = useTranslation(['event']);

  const itinerary = experience.hyProperties?.itinerary;

  const days = useMemo(() => {
    if (!itinerary) return {};
    return itinerary.reduce<Record<string, ExperienceItinerary[]>>((acc, item) => {
      if (!acc[item.day]) {
        acc[item.day] = [item];
      } else {
        acc[item.day].push(item);
      }
      return acc;
    }, {});
  }, [itinerary]);

  if (!itinerary?.length) return null;

  return (
    <div className='border-y border-white/20 py-6'>
      <h2 className='mb-6 text-xl'>{t('event:itinerary')}</h2>{' '}
      <CollapsableText minimum={6}>
        <ul className='space-y-6 border-white/20 ltr:ml-3 ltr:border-l rtl:mr-3 rtl:border-r'>
          {Object.entries(days).map(([day, items]) => {
            return (
              <li
                key={day}
                className='relative before:absolute before:top-0 before:h-5 before:w-5 before:rounded-full before:bg-white ltr:pl-6 ltr:before:left-0 ltr:before:-translate-x-1/2 rtl:pr-6 rtl:before:right-0 rtl:before:translate-x-1/2'
              >
                <p className='mb-3 text-lg font-bold leading-none'>
                  {t('event:day')} {day}
                </p>

                <div className='text-text-secondary space-y-2'>
                  {items.map((item) => {
                    return (
                      <p key={item._id}>
                        <strong>- {item.starts_at}:</strong> {item.description[shortLang]}
                      </p>
                    );
                  })}
                </div>
              </li>
            );
          })}
        </ul>
      </CollapsableText>
    </div>
  );
};

export default Itinerary;
