import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAnalytics } from '@wbk/analytics';
import { useGetEventDetail } from '@wbk/contentful/api';
import { ShareModal } from '@wbk/ui';
import FullpageAnimatedLogo from '@/components/ui/spinners/FullpageAnimatedLogo';
import { eventJsonLd } from '@/components/headers/seo/json-ld';
import Breadcrumb from '@/components/common/Breadcrumb';
import EventTitleDetails from '@/components/events/TitleDetails';
import EventTitleDescription from '@/components/events/Description';
import Seo from '@/components/headers/seo';
import SimilarEvents from '@/components/events/SimilarEvents';
import Error404 from '@/components/error/404';
import { parseContentfulToAnalyticsEvent } from '@/util/events';
import EventGallery from '@/components/events/gallery/EventGallery';
import EventDetailSidebar from '@/components/events/Sidebar';

const EventDetailPage = () => {
  const { slug, lang } = useParams<{ lang: Language; slug: string }>();
  const { data, isLoading } = useGetEventDetail({ lang, limit: 1, where: { slug } });
  const event = data?.items?.[0];
  const { t } = useTranslation(['event']);
  const { viewItemEvent } = useAnalytics();

  useEffect(() => {
    // GA4 Event
    if (event) {
      viewItemEvent({
        event: parseContentfulToAnalyticsEvent(event),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [event]);

  if (isLoading) {
    return <FullpageAnimatedLogo />;
  }

  if (!event) {
    return <Error404 />;
  }

  return (
    <>
      <script
        type='application/ld+json'
        dangerouslySetInnerHTML={{
          __html: JSON.stringify(eventJsonLd(event, `/${lang}/events/${slug}`)),
        }}
      />

      <Seo
        title={event.seo?.title || event.title}
        description={event.seo?.description}
        image={event.seo?.image?.url || event.image11.url}
      />

      <section className='container flex items-center justify-between gap-2 py-4'>
        <Breadcrumb
          items={[
            {
              href: `/${lang}/explore?category=${event.category?.slug || ''}`,
              name: t('common:events'),
            },
            {
              name: event.title,
              href: event.slug,
              disabled: true,
              className: 'text-text-secondary',
            },
          ]}
        />
        <ShareModal
          data={{
            title: event?.title,
            text: event?.title,
          }}
          poster={event.image11.url}
          className='px-2 py-1.5 text-sm md:hidden'
        />
      </section>
      <section className='container flex flex-col-reverse gap-2 space-y-2 md:flex-col'>
        <EventTitleDetails event={event} />
        <EventGallery event={event} />
      </section>

      <section className='container flex flex-col-reverse gap-6 py-8 lg:flex-row'>
        <div className='grow'>
          <EventTitleDescription event={event} />
        </div>
        <aside className='mx-auto w-full shrink-0 grow space-y-6 lg:max-w-md'>
          <EventDetailSidebar event={event} />
        </aside>
      </section>

      <SimilarEvents event={event} />
    </>
  );
};

export default EventDetailPage;
