import { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { HideOnQueue } from '@wbk/queue';
import HideOnWebview from '../webview/HideOnWebview';

export type BreadcrumbItem = {
  name: string;
  href: string;
  disabled?: boolean;
  className?: string;
  hide?: boolean;
};

interface BreadcrumbProps {
  items: BreadcrumbItem[];
}

const Breadcrumb = ({ items }: BreadcrumbProps) => {
  return (
    <HideOnWebview>
      <HideOnQueue>
        <ul className='inline list-none flex-wrap items-center text-sm'>
          {items?.map(({ name, disabled, href, className, hide }, idx) => {
            if (hide) return null;
            return (
              <Fragment key={idx}>
                <li
                  className={`inline items-center capitalize leading-tight ${className ? className : ''}`}
                >
                  {disabled ? (
                    <span>{name}</span>
                  ) : (
                    <Link to={href}>
                      <span>{name}</span>
                    </Link>
                  )}
                </li>
                {idx !== items.length - 1 ? (
                  <img
                    src='/icons/common/arrow.svg'
                    width={22}
                    height={22}
                    className='inline rtl:rotate-180'
                    alt='breadcrumb seperator'
                  />
                ) : null}
              </Fragment>
            );
          })}
        </ul>
      </HideOnQueue>
    </HideOnWebview>
  );
};

export default Breadcrumb;
