import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button } from '@wbk/ui';

const Joinwebook = () => {
  const { lang } = useParams<{ lang: string }>();
  const { t } = useTranslation('common');

  return (
    <div className='flex flex-col items-center rounded-xl border border-white/20 p-4 text-center'>
      <h3 className='my-2 text-2xl font-semibold'>{t('common:join_webook.add_your_business')}</h3>

      <p className='mb-4 text-sm'>{t('common:join_webook.sell_more')}</p>

      <img
        src='/images/illustration/join-webook.png'
        alt='Join webook.com'
        className='mb-3'
        width={218}
        height={165}
      />

      <Button
        as={Link}
        to={`/${lang}/business`}
        className='h-11 w-full bg-white/20 hover:bg-white/10 active:bg-white/30'
        target='_blank'
      >
        {t('common:join_webook.register_now')}
      </Button>
    </div>
  );
};

export default Joinwebook;
