import { Experience } from '@wbk/contentful/api';
import Itinerary from './Itinerary';
import ExperienceListItems from './ListItems';
import ExperienceCancelRefund from './CancelRefundInfo';

type Props = {
  experience: Experience;
};

const ExperienceDetailInfo = ({ experience }: Props) => {
  return (
    <>
      <Itinerary experience={experience} />
      <ExperienceListItems experience={experience} type='included' />
      <ExperienceListItems experience={experience} type='excluded' />
      <ExperienceListItems experience={experience} type='guest_requirements' />
      <ExperienceCancelRefund experience={experience} />
    </>
  );
};

export default ExperienceDetailInfo;
