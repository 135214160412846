export const DEFAULT_LOGOS = [
  { src: '/icons/payments/mada.svg', alt: 'Mada Payment Logo', width: 41, height: 15 },
  { src: '/icons/payments/apple-pay.svg', alt: 'Apple Payment Logo', width: 41, height: 17 },
  { src: '/icons/payments/visa.svg', alt: 'Visa Payment Logo', width: 24, height: 23 },
  { src: '/icons/payments/mastercard.svg', alt: 'Mastercard Payment Logo', width: 29, height: 23 },
  {
    src: '/icons/payments/american-express.svg',
    alt: 'American Express Logo',
    width: 24,
    height: 23,
  },
];
export const TABBY_LOGO = {
  src: '/icons/payments/tabby.svg',
  alt: 'Tabby Logo',
  width: 38,
  height: 17,
};
