import { useMemo } from 'react';
import { useInfiniteQuery } from '@tanstack/react-query';
import {
  ContentfulEvent,
  Zone,
  getEventListing,
  getExperiences,
  getPackages,
  getRestaurants,
  getShows,
  getZones,
} from '@wbk/contentful/api';
import useLocalization from '@/context/localization/useLocalization';
import useExploreQuery from './useExploreQuery';

type Props = {
  predefined?: Parameters<typeof useExploreQuery>[0]['predefined'];
  enabled?: boolean;
};

const useExploreFetch = ({ predefined, enabled = true }: Props) => {
  const { detectLoading } = useLocalization();
  const { query, perPage, search } = useExploreQuery({ predefined });
  const canFetch = !!query && enabled;

  const {
    fetchNextPage: fetchMoreEvents,
    hasNextPage: hasMoreEvents,
    isFetching: eventsLoading,
    data: events,
  } = useInfiniteQuery({
    queryKey: ['getEvents', query],
    initialPageParam: 1,
    queryFn: ({ pageParam }) => getEventListing({ ...query, skip: (pageParam - 1) * perPage }),
    getNextPageParam: (lastPage, _allPages, lastPageParam) => {
      const totalRendered = lastPageParam * perPage;
      const total = lastPage.total;
      return total > totalRendered ? lastPageParam + 1 : undefined;
    },
    enabled: canFetch,
  });

  const {
    fetchNextPage: fetchMoreExperiences,
    hasNextPage: hasMoreExperiences,
    isFetching: experiencesLoading,
    data: experiences,
  } = useInfiniteQuery({
    queryKey: ['getExperiences', query],
    initialPageParam: 1,
    queryFn: ({ pageParam }) => getExperiences({ ...query, skip: (pageParam - 1) * perPage }),
    getNextPageParam: (lastPage, _allPages, lastPageParam) => {
      const totalRendered = lastPageParam * perPage;
      const total = lastPage.total;
      return total > totalRendered ? lastPageParam + 1 : undefined;
    },
    enabled: canFetch,
  });

  const {
    fetchNextPage: fetchMoreShows,
    hasNextPage: hasMoreShows,
    isFetching: showsLoading,
    data: shows,
  } = useInfiniteQuery({
    queryKey: ['getShows', query],
    initialPageParam: 1,
    queryFn: ({ pageParam }) => getShows({ ...query, skip: (pageParam - 1) * perPage }),
    getNextPageParam: (lastPage, _allPages, lastPageParam) => {
      const totalRendered = lastPageParam * perPage;
      const total = lastPage.total;
      return total > totalRendered ? lastPageParam + 1 : undefined;
    },
    enabled: canFetch,
  });

  const {
    fetchNextPage: fetchMoreRestaurants,
    hasNextPage: hasMoreRestaurants,
    isFetching: restaurantsLoading,
    data: restaurants,
  } = useInfiniteQuery({
    queryKey: ['getRestaurants', query],
    initialPageParam: 1,
    queryFn: ({ pageParam }) => getRestaurants({ ...query, skip: (pageParam - 1) * perPage }),
    getNextPageParam: (lastPage, _allPages, lastPageParam) => {
      const totalRendered = lastPageParam * perPage;
      const total = lastPage.total;
      return total > totalRendered ? lastPageParam + 1 : undefined;
    },
    enabled: canFetch,
  });

  const {
    fetchNextPage: fetchMorePackages,
    hasNextPage: hasMorePackages,
    isFetching: packagesLoading,
    data: packages,
  } = useInfiniteQuery({
    queryKey: ['getPackages', query],
    initialPageParam: 1,
    queryFn: ({ pageParam }) => getPackages({ ...query, skip: (pageParam - 1) * perPage }),
    getNextPageParam: (lastPage, _allPages, lastPageParam) => {
      const totalRendered = lastPageParam * perPage;
      const total = lastPage.total;
      return total > totalRendered ? lastPageParam + 1 : undefined;
    },
    enabled: canFetch,
  });

  const {
    fetchNextPage: fetchMoreZones,
    hasNextPage: hasMoreZones,
    isFetching: zonesLoading,
    data: zones,
  } = useInfiniteQuery({
    queryKey: ['getZones', query],
    initialPageParam: 1,
    queryFn: ({ pageParam }) => getZones({ ...query, skip: (pageParam - 1) * perPage }),
    getNextPageParam: (lastPage, _allPages, lastPageParam) => {
      const totalRendered = lastPageParam * perPage;
      const total = lastPage.total;
      return total > totalRendered ? lastPageParam + 1 : undefined;
    },
    select: (data) => ({
      pageParams: data.pageParams,
      pages: data.pages.map((page) => ({
        ...page,
        settledAt: 0,
      })),
    }),
    // Fetch zones only on search
    enabled: canFetch && !!search,
  });

  const allEvents = useMemo(() => {
    if (!canFetch) {
      return { total: 0, items: [] };
    }

    const allZones = zones?.pages || [];
    const allEvents = events?.pages || [];
    const allExperiences = experiences?.pages || [];
    const allShows = shows?.pages || [];
    const allRestaurants = restaurants?.pages || [];
    const allPackages = packages?.pages || [];

    // A hack for the sorting
    const queries = [allZones, allEvents, allExperiences, allShows, allRestaurants, allPackages];
    const all = queries.flat();
    const sortedBySettledAt = all
      .sort((a, b) => a.settledAt - b.settledAt)
      .flatMap((p) => p.items as (ContentfulEvent | Zone)[]);

    const total = queries.reduce((acc, q) => acc + (q[0]?.total || 0), 0);

    return {
      total,
      items: sortedBySettledAt,
    };
  }, [
    canFetch,
    events?.pages,
    experiences?.pages,
    packages?.pages,
    restaurants?.pages,
    shows?.pages,
    zones?.pages,
  ]);

  const loading =
    detectLoading ||
    eventsLoading ||
    experiencesLoading ||
    restaurantsLoading ||
    showsLoading ||
    packagesLoading ||
    zonesLoading;

  return {
    zones,
    allEvents: allEvents.items,
    total: allEvents.total,
    hasMoreEvents,
    hasMoreExperiences,
    hasMoreRestaurants,
    hasMoreShows,
    hasMorePackages,
    hasMoreZones,
    fetchMoreEvents,
    fetchMoreExperiences,
    fetchMoreRestaurants,
    fetchMoreShows,
    fetchMorePackages,
    fetchMoreZones,
    loading,
  };
};

export default useExploreFetch;
