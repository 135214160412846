import { useParams } from 'react-router-dom';
import { useGetSeasons } from '@wbk/contentful/api';
import { SeasonHeroBanner } from '@wbk/contentful';
import useLocalization from '@/context/localization/useLocalization';
import Error404 from '@/components/error/404';
import FullpageAnimatedLogo from '@/components/ui/spinners/FullpageAnimatedLogo';
import Seo from '@/components/headers/seo';
import PageSection from '@/components/section';

const SeasonDetailPage = () => {
  const { locale } = useLocalization();
  const { slug } = useParams<{ slug: string }>();
  const { data, isLoading } = useGetSeasons({
    lang: locale,
    limit: 1,
    where: { slug },
  });

  const season = data?.[0];

  if (isLoading) {
    return <FullpageAnimatedLogo />;
  }

  if (!season) {
    return <Error404 />;
  }

  return (
    <>
      <Seo title={season.title} description={season.subtitle} image={season.background.url} />

      <SeasonHeroBanner season={season} />

      {season.sectionsCollection.items.map((section, idx) => (
        <PageSection key={section.__typename + idx} section={section} />
      ))}
    </>
  );
};

export default SeasonDetailPage;
