import { useParams } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import {
  isSameDayFromMilli,
  isSameMonthFromMilli,
  isSameYearFromMilli,
  splitDateTime,
} from '@wbk/utils';
import { useTranslation } from 'react-i18next';
import { Typography } from '@wbk/ui';
import type { ContentfulSchedule } from '@wbk/contentful/api';

type Props = {
  schedule: ContentfulSchedule;
  className?: string;
  showIcon?: boolean;
};

const EventDateTime = ({ schedule, className, showIcon = true }: Props) => {
  const { lang } = useParams<{ lang: Language }>();
  const { t } = useTranslation('common');
  const title = schedule?.openTitle || t('common:date');

  if (schedule?.openScheduleText) {
    return (
      <div className={twMerge('flex items-center gap-1.5 text-sm', className)}>
        {showIcon && (
          <img
            src='/icons/common/calendar.svg'
            className='mt-px h-8 w-8 shrink-0'
            width={32}
            height={32}
            alt='calendar'
          />
        )}
        <div>
          <Typography variant='label-M'>{title}</Typography>
          <Typography variant='body-S' color='text-secondary'>
            {schedule.openScheduleText}
          </Typography>
        </div>
      </div>
    );
  }

  const open = schedule?.openDateTime ? new Date(schedule?.openDateTime).getTime() : Date.now();
  const close = schedule?.closeDateTime ? new Date(schedule?.closeDateTime).getTime() : open;
  const locale = lang?.split('-')?.[0] || 'en';

  const start = splitDateTime(open, `${locale}-GB`);
  const end = splitDateTime(close, `${locale}-GB`);

  const isSameYear = isSameYearFromMilli(open, close);
  const isSameMonth = isSameMonthFromMilli(open, close);
  const isSameDay = isSameDayFromMilli(open, close);

  return (
    <div className={twMerge('flex items-center gap-1.5 text-sm', className)}>
      {showIcon && (
        <img
          src='/icons/common/calendar.svg'
          className='h-8 w-8 shrink-0'
          width={32}
          height={32}
          alt='calendar'
        />
      )}
      <div>
        <Typography variant='label-M'>{title}</Typography>
        <Typography variant='body-S' color='text-secondary'>
          {isSameDay
            ? `${start.day} ${start.month} ${start.year} - ${start.time?.toUpperCase()}`
            : isSameYear && isSameMonth
              ? `${start.day} - ${end.day} ${start.month} ${start.year} | ${start.time?.toUpperCase()}`
              : isSameYear
                ? `${start.day} ${start.month} - ${end.day} ${end.month} ${end.year}`
                : `${start.day} ${start.month} ${start.year} - ${end.day} ${end.month} ${end.year}`}
        </Typography>
      </div>
    </div>
  );
};

export default EventDateTime;
