import { useRef } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Button, Input, Select } from '@wbk/ui';
import { useTranslation } from 'react-i18next';
import { type AccessibilityFormValues, AccessibilityStep } from './types';

const AccessibilityDisabilityForm = () => {
  const { t } = useTranslation(['common', 'event']);
  const { setValue, handleSubmit, watch, control } = useFormContext<AccessibilityFormValues>();
  const mode = watch('mode');
  const inputRef = useRef<HTMLInputElement | null>(null);
  const formRef = useRef<HTMLFormElement | null>(null);

  const onSubmit = () => {
    setValue('mode', AccessibilityStep.TICKET);
  };

  return (
    <form
      className='group space-y-4'
      id={`accessibility_step_${mode}`}
      onSubmit={handleSubmit(onSubmit)}
      ref={formRef}
    >
      <div
        className='absolute top-0 z-0 h-full w-full'
        onDragOver={(e) => {
          e.preventDefault();
        }}
        onDrop={(e) => {
          e.preventDefault();
          if (e.dataTransfer.files.length) {
            setValue('disability_card', e.dataTransfer.files[0]);
          }
          e.currentTarget.classList.remove('z-50');
          e.currentTarget.classList.add('z-0');
          formRef.current?.removeAttribute('data-dragging');
        }}
        onDragEnter={(e) => {
          e.currentTarget.classList.add('z-50');
          e.currentTarget.classList.remove('z-0');
          formRef.current?.setAttribute('data-dragging', 'true');
        }}
        onDragLeave={(e) => {
          e.currentTarget.classList.remove('z-50');
          e.currentTarget.classList.add('z-0');
          formRef.current?.removeAttribute('data-dragging');
        }}
      />

      <Controller
        name='disability_type'
        control={control}
        rules={{ required: t('common:validation.required') }}
        defaultValue=''
        render={({ field: { onChange, ...props }, fieldState: { error } }) => (
          <Select
            id='disability_type'
            {...props}
            portal={false}
            autoComplete='off'
            onChange={(selected) => onChange(selected?.value || '')}
            label={t('event:accessibility.disability_type')}
            className='z-10'
            options={['wheelchair_access', 'free_space_to_stretch_their_legs'].map((c) => ({
              id: c,
              value: c,
              text: t(`event:accessibility.${c}`),
            }))}
            error={error?.message}
          />
        )}
      />

      <Controller
        name='disability_card'
        control={control}
        rules={{ required: t('common:validation.required') }}
        render={({ field: { onChange, value, ...props }, fieldState: { error } }) => (
          <div className='space-y-2'>
            <div>
              <p className='font-semibold'>{t('event:accessibility.upload_disability_card')}</p>
              <p className='text-text-secondary text-sm'>
                {t('event:accessibility.upload_disability_card_desc')}
              </p>
            </div>
            <label
              htmlFor='disability_card'
              data-error={!!error?.message}
              className='border-paper bg-body-level-1 data-[error=true]:bg-error/5 data-[error=true]:border-error/40 hover:bg-body-level-2 focus:bg-body-level-2 group-data-[dragging=true]:bg-body-level-2 relative z-10 block cursor-pointer items-center rounded-md border border-dashed p-2'
            >
              <div className='flex items-center gap-2 text-sm'>
                <Button
                  theme='white'
                  shape='outlined'
                  className='px-2 py-1 font-semibold'
                  type='button'
                  onClick={() => {
                    inputRef.current?.click();
                  }}
                  aria-label={
                    value ? t('event:accessibility.change') : t('event:accessibility.upload')
                  }
                >
                  <img src='/icons/common/share.svg' width={18} height={18} alt='' />
                  {value ? t('event:accessibility.change') : t('event:accessibility.upload')}
                </Button>
                <p className='text-text-secondary line-clamp-1 group-data-[dragging=true]:hidden'>
                  {value ? value.name : t('event:accessibility.or_dnd')}
                </p>
                <p className='text-text-secondary line-clamp-1 hidden group-data-[dragging=true]:block'>
                  {t('event:accessibility.drop_to_upload')}
                </p>
              </div>
              <Input
                {...props}
                ref={inputRef}
                type='file'
                className='hidden'
                id='disability_card'
                accept='image/jpg,image/png,application/pdf,.pdf'
                onFileChange={(files) => {
                  const file = files?.[0];
                  if (file) {
                    onChange(file);
                  }
                }}
                error={error?.message}
              />
            </label>
            <p className='text-text-secondary text-xs'>{t('event:accessibility.upload_limit')}</p>
          </div>
        )}
      />
    </form>
  );
};

export default AccessibilityDisabilityForm;
