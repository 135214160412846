import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button } from '@wbk/ui';
import { useWebsiteLocale } from '@wbk/hooks';
import HideOnWebview from '../webview/HideOnWebview';

const Error404 = () => {
  const { t } = useTranslation('common');
  const { lang } = useWebsiteLocale();

  return (
    <section className='relative flex min-h-[80vh] w-full items-center justify-center py-20 before:fixed before:inset-0 before:-z-10 before:-mt-20 before:bg-[url(/images/wheel.png)] before:bg-cover after:fixed after:inset-0 after:z-[-1] after:bg-gradient-to-b after:from-black/5 after:to-black'>
      <div className='container space-y-10 py-20'>
        <h1 className='font-gellix inline h-full text-[160px]'>404</h1>
        <div className='space-y-2'>
          <h2 className='text-4xl uppercase'>{t('common:errors.404')}</h2>
          <p className='text-lg'>{t('common:errors.404_description')}</p>
        </div>
        <HideOnWebview>
          <Button
            as={Link}
            to={`/${lang}`}
            theme='white'
            shape='outlined'
            className='w-full max-w-xs'
          >
            {t('common:go_home')}
          </Button>
        </HideOnWebview>
      </div>
    </section>
  );
};

export default Error404;
