import {
  EventItemSkeleton as ContentfulEventItemSkeleton,
  EventListItemSkeleton,
} from '@wbk/contentful';

const EventItemSkeleton = ({ number = 6, mobile = false }) => {
  const array = Array.from(Array(number).keys());

  if (mobile) {
    return array.map((i) => <EventListItemSkeleton key={i} />);
  }

  return array.map((i) => <ContentfulEventItemSkeleton key={i} />);
};

export default EventItemSkeleton;
