import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useGetRestaurantDetails } from '@wbk/contentful/api';
import { CollapsableText } from '@wbk/contentful';
import FullpageAnimatedLogo from '@/components/ui/spinners/FullpageAnimatedLogo';
import { restaurantJsonLd } from '@/components/headers/seo/json-ld';
import Seo from '@/components/headers/seo';
import SimilarRestaurants from '@/components/restaurants/SimilarRestaurants';
import Error404 from '@/components/error/404';
import RestaurantDetails from '@/components/restaurants/RestaurantDetails';
import IconQAList from '@/components/restaurants/IconQAList';
import MenuCollection from '@/components/restaurants/MenuCollection';
import ZoneEntry from '@/components/restaurants/ZoneEntry';
import RestaurantSummary from '@/components/restaurants/RestaurantSummary';
import PaymentMethods from '@/components/common/PaymentMethods';
import NeedHelp from '@/components/common/NeedHelp';
import JoinWebook from '@/components/common/JoinWebook';
import RestaurantLocation from '@/components/restaurants/RestaurantLocation';
import Breadcrumb from '@/components/common/Breadcrumb';
import EventGallery from '@/components/events/gallery/EventGallery';

const RetaurantsDetailPage = () => {
  const { slug, lang } = useParams<{ lang: Language; slug: string }>();
  const { data, isLoading } = useGetRestaurantDetails({
    lang,
    limit: 1,
    where: { slug, visibility_not: null },
  });
  const { t } = useTranslation(['common', 'event']);

  if (isLoading) {
    return <FullpageAnimatedLogo />;
  }

  if (!data) {
    return <Error404 />;
  }

  return (
    <>
      <script
        type='application/ld+json'
        dangerouslySetInnerHTML={{
          __html: JSON.stringify(restaurantJsonLd(data, `/${lang}/restaurants/${slug}`)),
        }}
      />

      <Seo
        title={data.seo?.title || data.title}
        description={data.seo?.description}
        image={data.seo?.image?.url || data.image11.url}
      />

      <section className='container relative py-4'>
        <Breadcrumb
          items={[
            {
              href: `/${lang}/explore?category=${data.category?.slug || ''}`,
              name: t('common:restaurants'),
            },
            {
              name: data.title,
              href: data.slug,
              disabled: true,
              className: 'text-text-secondary',
            },
          ]}
        />
      </section>

      <section className='container space-y-2'>
        <EventGallery event={data} />
      </section>

      <section className='container flex flex-col gap-6 space-y-6 py-8 lg:flex-row'>
        <div>
          <RestaurantDetails event={data} />
          <div className='my-6 space-y-6 lg:hidden'>
            <RestaurantSummary data={data} />
            <PaymentMethods withTabby />
          </div>
          <IconQAList
            data={data?.about}
            additionalItems={<RestaurantLocation location={data.location} className='md:hidden' />}
            iconsListClassName='flex flex-col flex-wrap md:flex-row md:gap-8 [&_h4]:leading-5'
            className='border-t-0'
          />
          <RestaurantLocation location={data.location} className='hidden md:block' />
          <MenuCollection data={data?.menuCollection} />
          <IconQAList
            data={data?.thingsToKnow}
            iconsListClassName='grid grid-cols-[repeat(auto-fill,minmax(90px,1fr))] gap-2 text-center text-[11px] [&_h4]:font-normal [&_h4]:leading-3 py-2 [&_qa-item]:py-3'
          />
          <IconQAList data={data?.attendees} />
          <ZoneEntry data={data} />

          {data.richTerms && (
            <div className='space-y-4 py-6'>
              <h2 className='text-xl font-semibold md:text-2xl'>
                {t('event:cancellation_refund_policy')}
              </h2>
              <CollapsableText
                minimum={4}
                text={data.richTerms}
                btnProps={{ 'aria-label': 'Read More About Terms' }}
                className='text-text text-sm'
              />
            </div>
          )}

          <div className='xl:hidden'>
            <NeedHelp />
          </div>
        </div>

        <aside className='hidden w-full max-w-md shrink-0 grow space-y-6 lg:block xl:max-w-xl'>
          <RestaurantSummary data={data} />
          <PaymentMethods withTabby />
          <NeedHelp />
          <JoinWebook />
        </aside>
      </section>

      <SimilarRestaurants event={data} />

      <div className='container mb-6 xl:hidden'>
        <JoinWebook />
      </div>
    </>
  );
};

export default RetaurantsDetailPage;
