import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import { Button, ImageWithPlaceholder } from '@wbk/ui';
import { ContentfulEvent } from '@wbk/contentful/api';
import { Organization } from '@wbk/api';
import { useCurrency } from '@wbk/currency';
import { resolveContentfulEventType } from '@/util/events';
import EventDateTime from '../events/DateTime';

type Props = {
  organization: Organization;
  event: ContentfulEvent;
  onClick?: () => void;
};

const OrganiationListItem = ({ organization, event, onClick }: Props) => {
  const { lang } = useParams<{ lang: Language }>();
  const { t } = useTranslation(['common', 'event']);
  const resolvedType = resolveContentfulEventType(event.__typename);
  const { exchangePrice, symbol } = useCurrency();
  return (
    <Button
      queueProtected
      as={Link}
      to={`/${lang}/${resolvedType}/${event.slug}`}
      onClick={onClick}
      className='w-full justify-start gap-2 p-0.5 ltr:text-left rtl:text-right'
      theme='white'
      shape='text'
      phill={false}
      rounded
    >
      <ImageWithPlaceholder
        src={event.image11?.url}
        className='h-20 w-20 rounded-md object-cover'
      />
      <div className='space-y-px'>
        <p className='text-text-secondary flex items-center gap-1 text-xs'>{organization.name}</p>
        <p className='font-semibold'>{event.title}</p>
        {event.schedule && (
          <EventDateTime
            schedule={event.schedule}
            className='text-text-secondary text-xs'
            showIcon={false}
          />
        )}
        {event.startingPrice > 0 && (
          <p className='inline-flex items-baseline gap-2 text-sm'>
            {t('event:starts_from_price', {
              price: exchangePrice(event.startingPrice, event.currencyCode),
              currency: symbol,
            })}
          </p>
        )}
      </div>
    </Button>
  );
};

export default OrganiationListItem;
