import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';
import { Button } from '@wbk/ui';
import { Restaurant } from '@wbk/contentful/api';

type Props = {
  location?: Restaurant['location'];
  className?: string;
};

const RestaurantLocation = ({ location, className }: Props) => {
  const { t } = useTranslation('event');

  if (!location) return null;

  return (
    <div className={twMerge('md:border-paper md:border-t md:py-6', className)}>
      <h4 className='text:xl mb-6 hidden md:block md:text-2xl'>{t('event:location')}</h4>

      <div className='flex flex-row items-center gap-4'>
        <div className='bg-body-level-1 grid h-12 w-12 shrink-0 place-items-center rounded-full'>
          <img
            className='shrink-0 object-contain'
            src='/icons/common/location-marker.svg'
            width={15}
            height={16}
            alt='location'
          />
        </div>

        <div className='flex grow items-center justify-between gap-2 md:flex-col md:items-start'>
          <div className='space-y-1'>
            <h4 className='text-sm text-white md:hidden'>{t('event:location')}</h4>
            <p className='md:text-text text-text-secondary text-sm'>{location.title}</p>
          </div>

          <Button
            as='a'
            target='_blank'
            rel='noreferrer'
            href={`https://www.google.com/maps/search/?api=1&query=${location.location.lat},${location.location.lon}`}
            className='shrink-0 border-white/30 py-1 text-sm'
            theme='white'
            shape='outlined'
          >
            {t('event:directions')}
            <img
              src='/icons/common/location.svg'
              width={15}
              height={16}
              className='shrink-0 rtl:scale-x-[-1]'
              alt='direction'
            />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default RestaurantLocation;
