import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import { Button } from '@wbk/ui';

const NeedHelp = () => {
  const { t } = useTranslation(['event']);

  const { lang } = useParams<{ lang: string }>();
  return (
    <div className='flex items-center justify-between rounded-xl border border-white/20 p-4'>
      <p className='text-xl font-semibold'>{t('event:need_help')}</p>

      <Button
        as={Link}
        to={`/${lang}/faqs`}
        arrow
        className='h-8 bg-white/20 text-sm hover:bg-white/10 active:bg-white/30'
      >
        {t('event:read_faqs')}
      </Button>
    </div>
  );
};

export default NeedHelp;
