import { motion } from 'framer-motion';

const ResellBottomHand = ({ className = '' }) => {
  return (
    <motion.svg
      width='64'
      height='86'
      viewBox='0 0 64 86'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
      variants={{
        visible: { y: '0%' },
        hidden: { y: '100%' },
      }}
      transition={{ type: 'spring', damping: 18, delay: 0.15 }}
    >
      <g opacity='0.26'>
        <path
          d='M24.3401 8.49727C24.3401 8.49727 25.6954 14.8698 24.448 14.8864L24.8259 22.544C24.8259 22.544 20.6437 23.9158 15.6425 26.203L16.676 50.4734C16.676 50.4734 18.2565 54.4026 17.3223 58.2433L20.3427 62.1869C20.3427 62.1869 18.9414 71.3857 20.9801 72.9585C23.0493 74.5264 26.3637 71.6714 26.3637 71.6714C26.3637 71.6714 24.3098 85.4773 41.4904 93.4483C49.4926 98.2332 61.5251 127.267 63.1249 131.506C64.0352 134.013 70.4681 134.554 70.4681 134.554C70.4681 134.554 72.7361 125.495 72.7521 125.211C72.7682 124.927 59.7927 95.8603 59.7927 95.8603L33.7302 65.1218L24.4432 45.6084L26.9698 17.6928L27.0513 10.7414L24.3401 8.49727Z'
          fill='black'
        />
      </g>
      <path
        d='M22.4828 62.0868C22.4828 62.0868 20.6359 69.327 22.0361 70.8168C23.4362 72.3066 28.0206 70.3377 28.9045 64.6613C29.7934 59.0154 27.8039 57.9346 27.8039 57.9346L22.4828 62.0868Z'
        fill='white'
      />
      <path
        d='M35.2797 50.3357L35.29 19.4565C35.29 19.4565 30.7128 17.2677 30.0538 12.0939L12.3607 12.8324C12.3607 12.8324 12.9489 16.2365 7.4092 20.5807L7.401 55.8662C7.401 55.8662 11.6838 58.1656 12.8941 63.8431L28.7836 62.5871C28.7836 62.5871 29.611 55.4198 34.9731 54.5737L35.2797 50.3357Z'
        fill='#EF4444'
      />
      <path
        d='M27.3587 60.945L14.1521 61.9809L14.1017 61.8641C12.8655 58.1286 10.4736 55.926 9.12776 54.9586L9.05692 54.9077L9.04696 54.8468L9.03964 21.3765L9.09554 21.3361C12.3081 18.6228 13.436 16.1568 13.8007 14.5657L13.8112 14.439L28.5884 13.8338L28.6083 13.9555C29.6625 17.5333 32.3183 19.6302 33.4063 20.3585L33.4772 20.4094L33.4921 20.5008L33.5814 50.2698L33.3749 53.2101L33.2886 53.2555C33.1109 53.3158 32.9637 53.3711 32.8215 53.4569C29.3234 54.9981 27.9382 58.7563 27.4096 60.8742L27.3587 60.945ZM14.3812 61.6621L27.1213 60.6401C27.6703 58.4564 29.1164 54.6882 32.6704 53.1066C32.8127 53.0208 32.9294 52.9705 33.0766 52.9151L33.2571 50.1979L33.2436 20.5102C32.0848 19.731 29.4949 17.6545 28.4152 14.1121L14.135 14.6986C13.7448 16.325 12.5815 18.7656 9.39937 21.4739L9.40226 54.7261C10.7631 55.7848 13.1549 57.9874 14.3812 61.6621Z'
        fill='#0E0E44'
      />
      <path
        d='M29.6963 31.8728L23.5899 32.3719L20.5472 37.6514L18.2213 32.0315L12.2317 30.7613L16.8627 26.7846L16.2114 20.7032L21.4196 23.8826L26.9841 21.4095L25.5773 27.3276L29.6963 31.8728Z'
        fill='black'
      />
      <path
        d='M9.74069 41.5132L9.27417 41.527L9.28524 41.2127L9.75177 41.1989L9.74069 41.5132Z'
        fill='black'
      />
      <path
        d='M32.0539 40.5502L31.1208 40.5778L31.1014 40.2685L32.0345 40.2408L32.0539 40.5502ZM30.1927 40.6359L29.2597 40.6636L29.2403 40.3542L30.1734 40.3266L30.1927 40.6359ZM28.3316 40.7217L27.3986 40.7493L27.3792 40.44L28.3122 40.4123L28.3316 40.7217ZM26.5009 40.8024L25.5679 40.8301L25.5485 40.5207L26.4816 40.4931L26.5009 40.8024ZM24.6348 40.8578L23.7018 40.8854L23.6824 40.5761L24.6155 40.5484L24.6348 40.8578ZM22.7737 40.9435L21.8407 40.9712L21.8213 40.6618L22.7544 40.6342L22.7737 40.9435ZM20.9126 41.0293L19.9795 41.0569L19.9602 40.7476L20.8932 40.7199L20.9126 41.0293ZM19.0515 41.115L18.1184 41.1427L18.0991 40.8333L19.0321 40.8057L19.0515 41.115ZM17.1854 41.1703L16.2523 41.198L16.233 40.8886L17.166 40.861L17.1854 41.1703ZM15.3242 41.2561L14.3912 41.2838L14.3718 40.9744L15.3049 40.9467L15.3242 41.2561ZM13.4631 41.3418L12.5301 41.3695L12.5107 41.0601L13.4438 41.0325L13.4631 41.3418ZM11.602 41.4276L10.6689 41.4553L10.6496 41.1459L11.5826 41.1182L11.602 41.4276Z'
        fill='black'
      />
      <path
        d='M33.4231 40.5137L32.9565 40.5275L32.9676 40.2132L33.4341 40.1994L33.4231 40.5137Z'
        fill='black'
      />
      <path
        d='M24.3141 8.72014C24.3141 8.72014 21.5039 12.5552 16.0561 14.978L7.80816 48.6114C7.80816 48.6114 12.8613 54.4727 11.2104 58.8994L27.2427 60.8078L19.7961 49.8689L24.3141 8.72014Z'
        fill='#231F20'
      />
      <path
        d='M37.1251 50.5344L44.8904 20.6365C44.8904 20.6365 41.011 17.3647 41.6652 12.1947L24.3656 8.46227C24.3656 8.46227 24.0817 11.884 17.6388 14.7196L8.78603 48.8583C8.78603 48.8583 12.361 52.1799 12.0853 57.9442L27.7906 60.7187C27.7906 60.7187 30.4066 53.9776 35.7797 54.5361L37.1251 50.5344Z'
        fill='#987BFF'
      />
      <path
        d='M26.863 58.8702L13.7931 56.5396L13.7732 56.4178C13.5364 52.4875 11.7445 49.7492 10.724 48.4786L10.6836 48.4227L10.7041 48.3568L19.0903 15.9509L19.1462 15.9105C22.1878 14.444 24.2974 12.6299 25.3987 10.5746L25.4447 10.4733L39.8914 13.5787L39.8809 13.7054C39.9666 17.2854 41.8299 19.887 42.9417 21.1427L42.9821 21.1986L35.4886 50.0832L34.5372 52.8641L34.4459 52.8791C34.3546 52.894 34.2633 52.9089 34.167 52.8934C34.0757 52.9084 34.0148 52.9183 33.9235 52.9333C29.9666 53.5807 27.6588 57.2398 26.848 58.7789L26.863 58.8702ZM14.0676 56.3072L26.6864 58.5553C27.5736 56.91 29.9218 53.3068 33.9395 52.6494C34.0308 52.6344 34.1222 52.6195 34.2184 52.635C34.2793 52.625 34.3098 52.6201 34.3706 52.6101L35.2606 50.0268L42.7181 21.3043C41.6014 20.0182 39.7685 17.4116 39.6269 13.872L25.6361 10.8795C24.474 12.9448 22.3643 14.7589 19.3532 16.2204L11.0284 48.4288C12.0743 49.664 13.8053 52.4123 14.0676 56.3072Z'
        fill='#0E0E44'
      />
      <path
        d='M36.3452 31.2538L30.3296 30.2067L26.0423 34.5647L25.1829 28.5486L19.7352 25.8147L25.204 23.1385L26.089 17.0869L30.3353 21.4551L36.3569 20.4385L33.5217 25.8403L36.3452 31.2538Z'
        fill='white'
      />
      <path
        d='M14.2184 35.1818L14.1628 35.4852L14.6179 35.5685L14.6735 35.2651L14.2184 35.1818Z'
        fill='black'
      />
      <path
        d='M36.4456 40.2695L35.5386 40.0741L35.6155 39.7803L36.5225 39.9756L36.4456 40.2695ZM34.6315 39.8787L33.7245 39.6834L33.8014 39.3895L34.7085 39.5849L34.6315 39.8787ZM32.8175 39.488L31.9104 39.2926L31.9873 38.9988L32.8944 39.1942L32.8175 39.488ZM31.0034 39.0973L30.0963 38.9019L30.1733 38.6081L31.0803 38.8034L31.0034 39.0973ZM29.1589 38.7115L28.2518 38.5162L28.3287 38.2223L29.2358 38.4177L29.1589 38.7115ZM27.3448 38.3208L26.4377 38.1254L26.5147 37.8316L27.4217 38.0269L27.3448 38.3208ZM25.5307 37.9301L24.6237 37.7347L24.7006 37.4408L25.6076 37.6362L25.5307 37.9301ZM23.7166 37.5393L22.8096 37.344L22.8865 37.0501L23.7936 37.2455L23.7166 37.5393ZM21.9026 37.1486L20.9955 36.9532L21.0724 36.6594L21.9795 36.8547L21.9026 37.1486ZM20.0885 36.7579L19.1814 36.5625L19.2584 36.2687L20.1654 36.464L20.0885 36.7579ZM18.244 36.3721L17.3369 36.1768L17.4139 35.8829L18.3209 36.0783L18.244 36.3721ZM16.4299 35.9814L15.5229 35.786L15.5998 35.4922L16.5068 35.6875L16.4299 35.9814Z'
        fill='black'
      />
      <path
        d='M37.43 40.1842L37.3745 40.4875L37.8296 40.5708L37.8851 40.2674L37.43 40.1842Z'
        fill='black'
      />
      <path
        d='M60.8545 83.2481C56.3144 77.0841 58.5094 63.5677 57.7536 56.847C56.9977 50.1264 51.8072 51.257 46.7364 46.2424C41.6656 41.2279 39.5 41.3635 39.5 41.3635L37.1253 50.534C37.1253 50.534 36.3466 48.0675 34.6217 44.9744C31.6223 39.0584 26.9631 40.5708 26.9946 43.6284C27.0261 46.686 26.8766 50.9296 28.2378 65.5518C28.7976 88.8371 42.3235 89.5617 42.3235 89.5617C64.0224 116.17 70.4937 134.519 70.4937 134.519C75.0334 133.995 77.1922 132.673 80.3069 125.35C83.4471 117.992 88.041 112.834 88.041 112.834C74.4223 100.655 65.3946 89.412 60.8545 83.2481ZM44.1712 58.0694L39.108 57.3039C38.9016 55.0875 37.7273 52.6857 37.7273 52.6857L44.356 57.289L44.1712 58.0694Z'
        fill='white'
      />
      <path
        d='M43.0216 66.1334C42.9912 66.1384 42.9607 66.1434 42.9607 66.1434C42.7123 66.1528 42.4898 65.9391 42.4804 65.6907C42.4815 65.3154 42.4274 56.1986 46.6577 53.975C46.8862 53.8439 47.1546 53.9562 47.2808 54.1543C47.412 54.3829 47.2996 54.6513 47.1015 54.7775C43.3637 56.7642 43.3985 65.5717 43.4134 65.663C43.4228 65.9115 43.2347 66.0985 43.0216 66.1334Z'
        fill='black'
      />
      <path
        d='M44.1827 57.5673C44.0609 57.5872 43.9342 57.5767 43.8584 57.4953L38.0748 53.4725C37.8623 53.3198 37.8174 53.0458 37.9702 52.8333C38.1229 52.6208 38.3968 52.576 38.6094 52.7287L44.393 56.7515C44.6055 56.9043 44.6503 57.1782 44.4976 57.3907C44.4162 57.4665 44.3044 57.5473 44.1827 57.5673Z'
        fill='black'
      />
      <path
        d='M43.6987 58.4278C43.6378 58.4378 43.6073 58.4428 43.5415 58.4223L39.5071 57.8323C39.2487 57.7808 39.0871 57.5573 39.1081 57.3038C39.1596 57.0454 39.3832 56.8838 39.6367 56.9048L43.671 57.4948C43.9294 57.5463 44.091 57.7698 44.07 58.0233C44.044 58.2463 43.8813 58.398 43.6987 58.4278Z'
        fill='black'
      />
      <path
        d='M30.7076 48.3653C29.6119 48.5446 28.8769 48.446 28.4724 48.0747C28.3208 47.912 28.1188 47.6325 28.1963 47.1511C28.2798 46.3248 28.1299 45.5993 28.0253 44.9601C27.8156 43.8693 27.6158 42.8394 29.022 42.2656C31.1897 41.3796 32.1366 41.8185 33.498 44.4084C34.0425 45.4444 34.3646 46.2668 34.0635 46.9099C33.737 47.5883 32.8488 47.8899 31.1033 48.3005C30.9207 48.3304 30.7989 48.3503 30.7076 48.3653ZM30.3142 42.5229C30.0098 42.5727 29.6546 42.6934 29.2129 42.8594C28.3347 43.2218 28.3839 43.7138 28.6036 44.8655C28.7386 45.4997 28.8731 46.3215 28.8044 47.2391C28.7784 47.4621 28.8288 47.5789 28.8996 47.6298C29.0109 47.7366 29.4918 48.0017 30.9428 47.7017C32.4193 47.3664 33.2566 47.1356 33.4609 46.6647C33.6241 46.3254 33.4636 45.7267 32.9241 44.7211C31.9407 42.9131 31.3187 42.3586 30.3142 42.5229Z'
        fill='black'
      />
      <path
        d='M22.4033 70.1943C22.1903 70.2292 22.0586 70.1883 22.0281 70.1932C21.866 70.1573 21.7752 69.9846 21.8112 69.8224C21.8472 69.6603 22.0198 69.5695 22.182 69.6055C22.5063 69.6775 23.2578 69.3045 23.679 67.4854C24.1002 65.6664 23.8439 64.8645 23.5357 64.5086C23.2983 64.2037 23.0194 64.2181 22.9889 64.223C22.8318 64.2175 22.6851 64.0852 22.6906 63.9281C22.6962 63.7709 22.8284 63.6242 22.9806 63.5993C23.1987 63.5949 23.6497 63.6774 23.9983 64.0891C24.544 64.7499 24.6419 65.9215 24.2617 67.6088C23.9844 68.7793 23.5511 69.569 22.9568 69.9475C22.7636 70.1041 22.5555 70.1694 22.4033 70.1943Z'
        fill='black'
      />
      <path
        d='M72.3387 134.717C71.943 134.782 71.5168 134.852 71.0553 134.896C70.8118 134.936 70.5639 134.758 70.5495 134.479C70.5097 134.235 70.7183 133.982 70.9668 133.973C77.0128 133.296 77.6265 131.508 79.5337 126.164C79.641 125.865 79.7739 125.531 79.8763 125.202C81.7834 119.858 86.2212 114.319 87.4222 112.873C72.1261 98.6552 65.6911 90.2699 61.4078 84.7202C61.0896 84.3035 60.7764 83.9172 60.4936 83.5259C56.9275 78.9215 57.1772 69.9424 57.3455 62.758C57.3754 61.2218 57.4152 59.7464 57.4191 58.4331C57.3993 53.1547 55.4856 52.1552 52.2454 50.4977C51.086 49.9061 49.7545 49.2176 48.3428 48.2298C48.3074 48.2043 48.3074 48.2043 48.2719 48.1789C41.7694 42.0547 39.4833 41.835 39.4529 41.84C39.1994 41.819 39.0074 41.6004 39.0284 41.3469C39.0494 41.0934 39.2376 40.9064 39.4861 40.897C39.7346 40.8876 42.1474 41.1178 48.9078 47.481C50.2792 48.413 51.5448 49.081 52.6738 49.6776C55.9544 51.391 58.339 52.5947 58.3267 58.4409C58.3278 59.7846 58.283 61.2295 58.2581 62.7962C58.0698 69.8589 57.8511 78.6454 61.2202 83.0008C61.503 83.392 61.8162 83.7783 62.1344 84.195C66.4227 89.7751 72.8981 98.2164 88.3663 112.531C88.5484 112.688 88.5677 112.998 88.41 113.18C88.3591 113.251 82.8741 119.648 80.7473 125.559C80.6145 125.894 80.5121 126.223 80.4047 126.522C78.6 131.536 77.787 133.826 72.3387 134.717Z'
        fill='black'
      />
      <path
        d='M44.426 69.5597C44.1825 69.5996 43.9345 69.4214 43.8947 69.1779C43.3618 65.921 39.1321 62.8003 39.0663 62.7798C38.9246 62.678 38.8643 62.5003 38.8698 62.3432C39.5965 56.6613 36.7886 51.1515 36.7482 51.0956C36.7432 51.0652 36.7382 51.0347 36.7028 51.0093C34.5889 44.0108 32.2878 41.9809 30.7377 41.4844C29.4865 41.0954 28.5468 41.6554 28.2164 41.9283C27.3879 42.5951 27.3674 42.661 27.4598 45.7086C27.5019 46.9206 27.5627 48.6295 27.566 50.9416C27.971 59.7199 28.6516 66.1715 28.6616 66.2324C28.7014 66.4759 28.4878 66.6984 28.2443 66.7382C28.0008 66.7781 27.7529 66.5999 27.7385 66.3209C27.7285 66.2601 27.0075 59.7525 26.628 50.9389C26.6197 48.5963 26.5638 46.9178 26.5218 45.7058C26.4194 42.5973 26.4056 42.1308 27.6253 41.1811C28.0675 40.8275 29.3171 40.0605 30.994 40.5675C33.5623 41.3661 35.7427 44.7596 37.5506 50.6518C37.8279 51.2002 40.4084 56.466 39.773 62.1329C40.7038 62.8557 44.2091 65.7511 44.737 68.9775C44.8477 69.2719 44.6695 69.5199 44.426 69.5597Z'
        fill='black'
      />
      <path
        d='M37.175 50.8383C37.1445 50.8433 37.0836 50.8532 37.0482 50.8278C36.8861 50.7918 36.7953 50.6191 36.8313 50.457L44.5656 20.7517C43.8628 20.0853 40.8954 17.0393 41.3603 12.4316L25.3275 8.99199C25.1654 8.95602 25.0442 8.78833 25.0801 8.62618C25.1161 8.46403 25.2838 8.34284 25.446 8.37882L41.7372 11.8699C41.8994 11.9059 41.9851 12.0481 41.9796 12.2053C41.3514 17.1522 45.0587 20.3272 45.0991 20.3831C45.1749 20.4645 45.2253 20.5812 45.2147 20.708L37.4495 50.6059C37.3781 50.7426 37.2967 50.8184 37.175 50.8383Z'
        fill='black'
      />
    </motion.svg>
  );
};

export default ResellBottomHand;
