import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import type { AccessibilityFormValues } from './types';

const AccessibilitySteps = () => {
  const { t } = useTranslation('event');
  const { watch } = useFormContext<AccessibilityFormValues>();
  const mode = watch('mode') || 0;

  return (
    <div className='grid grid-cols-3 gap-2'>
      <div className='space-y-1'>
        <div className='bg-paper relative h-0.5 overflow-hidden rounded-md'>
          <div
            data-mode={mode}
            className='absolute inset-0 w-full rounded-md bg-white transition-all data-[mode=0]:w-[80%]'
          />
        </div>
        <p className='text-text-secondary text-xs'>{t('event:accessibility.step_info')}</p>
      </div>

      <div className='space-y-1'>
        <div className='bg-paper relative h-0.5 overflow-hidden rounded-md'>
          <div
            data-mode={mode}
            className='absolute inset-0 w-full rounded-md bg-white transition-all data-[mode=0]:w-0 data-[mode=1]:w-[80%] data-[mode=1]:duration-1000'
          />
        </div>
        <p className='text-text-secondary text-xs'>{t('event:accessibility.step_disability')}</p>
      </div>

      <div className='space-y-1'>
        <div className='bg-paper relative h-0.5 overflow-hidden rounded-md'>
          <div
            data-mode={mode}
            className='absolute inset-0 w-full rounded-md bg-white transition-all data-[mode=0]:w-0 data-[mode=1]:w-0 data-[mode=2]:w-[80%] data-[mode=2]:duration-1000'
          />
        </div>
        <p className='text-text-secondary text-xs'>{t('event:accessibility.step_ticket')}</p>
      </div>
    </div>
  );
};

export default AccessibilitySteps;
