import { useEffect, useState } from 'react';
import { Button, ImageWithPlaceholder, Modal } from '@wbk/ui';
import { useSearchParams } from 'react-router-dom';
import { useAuth } from '@wbk/auth';
import { useTranslation } from 'react-i18next';
import { ClockIcon } from '@wbk/svg';
import { twMerge } from 'tailwind-merge';
import { EventItemLocation, EventItemType } from '@wbk/contentful';
import type { ContentfulEvent } from '@wbk/contentful/api';

type Props = {
  event: ContentfulEvent;
};

const QueueJumpInfo = ({ event }: Props) => {
  const { t } = useTranslation('event');
  const [show, setShow] = useState(false);
  const [search] = useSearchParams();
  const queueJumpPosition = search.get('queue_jump_online');
  const { user } = useAuth({ autoFetch: !!queueJumpPosition });
  const plan = user?.user_subscriptions?.[0]?.plan;

  useEffect(() => {
    // If user exists and queueJumpPosition is > 0, show the modal
    const position = Number(queueJumpPosition);
    if (user && position > 0) {
      setShow(true);
    }
  }, [queueJumpPosition, user]);

  return (
    <Modal
      closeButton
      ignoreHistory
      show={show}
      size='lg'
      contentClassName='p-4'
      onClose={() => {
        setShow(false);
      }}
      body={() => (
        <>
          <div className='p-4 md:p-6'>
            <h2 className='text-2xl font-semibold'>
              {t('event:queue_jump.title', {
                name: plan?.plan_group?.name || '',
              })}
            </h2>
            <p className='text-text-secondary'>
              {t('event:queue_jump.subtitle', { email: user?.email })}
            </p>
          </div>

          <img
            src={plan?.mobile_banner || plan?.banner}
            alt={`${plan?.name} banner`}
            height={240}
            width={240}
            className='-my-6 mx-auto max-h-60 w-auto'
          />

          <hr className='border-paper' />
          <div className='space-y-4 p-4 md:px-6'>
            <div className='flex items-center gap-2'>
              <ClockIcon className='h-6 w-6' />
              {t('event:queue_jump.waiting', {
                total: queueJumpPosition,
              })}
            </div>

            <div className='xs:items-center xs:flex-row flex flex-col justify-between gap-4'>
              <div className='flex items-center gap-4'>
                <div
                  data-premium={event.eventType === 'premium'}
                  data-rs={event.eventType === 'riyadh_season'}
                  className={twMerge(
                    'relative h-24 w-24 shrink-0 rounded-lg',
                    'from-[#2AD0CA] via-[#E1F664] to-[#FEB0FE] data-[rs=true]:bg-[#1D58FF] data-[premium=true]:bg-gradient-to-br data-[premium=true]:p-0.5 data-[rs=true]:p-0.5'
                  )}
                >
                  <div
                    data-premium={event.eventType === 'premium'}
                    data-rs={event.eventType === 'riyadh_season'}
                    className='absolute bottom-0.5 left-0 z-10 hidden w-full data-[premium=true]:block data-[rs=true]:block'
                  >
                    <div
                      data-premium={event.eventType === 'premium'}
                      data-rs={event.eventType === 'riyadh_season'}
                      className='mx-0.5 flex items-center justify-center gap-px rounded-b-md p-0.5 data-[premium=true]:bg-white data-[rs=true]:bg-[#1D58FF]'
                    >
                      <img
                        src={
                          event.eventType === 'riyadh_season'
                            ? '/icons/season/riyadh_season.svg'
                            : '/icons/season/premium.svg'
                        }
                        className='h-4 w-4 shrink-0 p-px'
                        alt=''
                      />
                      <span
                        data-premium={event.eventType === 'premium'}
                        data-rs={event.eventType === 'riyadh_season'}
                        className='truncate text-xs capitalize data-[premium=true]:text-black data-[rs=true]:text-white'
                      >
                        {t(`event:${event.eventType}_mini`)}
                      </span>
                    </div>
                  </div>
                  <ImageWithPlaceholder
                    src={event.image11?.url}
                    className='h-full w-full rounded-md object-cover'
                  />
                </div>
                <div className='space-y-px [&>div:nth-child(1)]:text-xs [&>div:nth-child(3)]:text-sm'>
                  <EventItemType event={event} />
                  <p className='line-clamp-2 text-sm'>{event.title}</p>
                  <EventItemLocation
                    location={event.location}
                    className='text-text-secondary text-xs'
                  />
                </div>
              </div>

              <Button
                className='shrink-0'
                onClick={() => {
                  setShow(false);
                }}
              >
                {t('event:queue_jump.skip')}
              </Button>
            </div>
          </div>
        </>
      )}
    />
  );
};

export default QueueJumpInfo;
