import { Fragment } from 'react/jsx-runtime';
import { useDeviceDetect } from '@wbk/hooks';
import { AdSlotLB, AdSlotMLB, AdSlotMPU, useAds } from '@smc/ads';
import { EventItem, EventListItem } from '@wbk/contentful';
import type { ContentfulEvent, Zone } from '@wbk/contentful/api';

type Props = {
  events: (ContentfulEvent | Zone)[];
};

const ExploreGrid = ({ events }: Props) => {
  const { isMobile } = useDeviceDetect();
  const { exploreType, shouldRenderMpu, canRenderAds } = useAds();

  return events.map((event, i) => {
    return (
      <Fragment key={event.id + i}>
        <div className='mx-auto w-full'>
          {isMobile ? <EventListItem event={event} /> : <EventItem event={event} />}
        </div>

        {/* Ads */}
        {i === 0 && <AdSlotMLB adUnit={`${exploreType}/mlb`} className='mb-0 py-2' />}
        {i === 3 && <AdSlotLB adUnit={`${exploreType}/lb`} className='col-span-full' />}
        {canRenderAds && shouldRenderMpu(events.length, i) && (
          <AdSlotMPU
            slotId={`${event.id}-${i}`}
            adUnit={exploreType}
            className='flex grow flex-col overflow-hidden rounded-md'
          >
            <div className='grow'></div>
          </AdSlotMPU>
        )}
      </Fragment>
    );
  });
};

export default ExploreGrid;
