import { GtmTracker, WbkTracker } from '@wbk/analytics';

export const wbkTracker = new WbkTracker({
  baseDomain: process.env.VITE_PUBLIC_WAS_API_BASE!,
  log: process.env.VITE_PUBLIC_ENV !== 'production',
});

export const gtmTracker = new GtmTracker({
  gtmIds: process.env.VITE_PUBLIC_GOOGLE_TAG_MANAGER?.split(',') || [],
  log: process.env.VITE_PUBLIC_ENV !== 'production',
});
