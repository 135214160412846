import React from 'react';
import { RouteObject } from 'react-router-dom';
import HouseOfHypePage from '@/pages/experiences/house-of-hype';
import Error404 from '@/components/error/404';
import SPLPage from '@/pages/spl';
import SPLTeamsPage from '@/pages/spl/teams';

const AhliFcPage = React.lazy(() => import('@/pages/events/ahli-fc'));
const SaudiGamesPage = React.lazy(() => import('@/pages/events/saudi-games'));
const WTAPage = React.lazy(() => import('@/pages/events/wta'));
const WTADetailPage = React.lazy(() => import('@/pages/events/wta-detail'));
const IttihadPage = React.lazy(() => import('@/pages/events/ittihad'));
const JCSAPage = React.lazy(() => import('@/pages/events/jcsa'));
const JCSAEventGroupPage = React.lazy(() => import('@/pages/events/jcsa/group'));
const PageBySlug = React.lazy(() => import('@/pages/[slug]'));

export const customPagesRoutes: RouteObject[] = [
  {
    path: 'experiences/house-of-hype',
    element: <HouseOfHypePage />,
    errorElement: <Error404 />,
  },
  {
    path: 'events/ahli-fc',
    element: <AhliFcPage />,
    errorElement: <Error404 />,
  },
  {
    path: 'ittihad',
    element: <IttihadPage />,
    errorElement: <Error404 />,
  },
  {
    path: 'events/wta-finals-tickets',
    element: <WTAPage />,
    errorElement: <Error404 />,
  },
  {
    path: 'events/wta-finals-tickets-697196',
    element: <WTADetailPage slug='wta-finals-tickets-697196' />,
    errorElement: <Error404 />,
  },
  {
    path: 'events/wta-weekend-847283',
    element: <WTADetailPage slug='wta-weekend-847283' />,
    errorElement: <Error404 />,
  },
  {
    path: 'events/wta-weekdays-379274',
    element: <WTADetailPage slug='wta-weekdays-379274' />,
    errorElement: <Error404 />,
  },
  {
    path: 'events/wta-finals-package-121829',
    element: <WTADetailPage slug='wta-finals-package-121829' />,
    errorElement: <Error404 />,
  },
  {
    path: 'events/wta-hospitality-926801',
    element: <WTADetailPage slug='wta-hospitality-926801' />,
    errorElement: <Error404 />,
  },
  {
    path: 'events/jcsa-homepage',
    element: <JCSAPage />,
    errorElement: <Error404 />,
  },
  {
    path: 'events/saudi-cup-taif-season',
    element: <JCSAEventGroupPage slug='saudi-cup-taif-season' />,
    errorElement: <Error404 />,
  },
  {
    path: 'events/riyadh-racing-season',
    element: <JCSAEventGroupPage slug='riyadh-racing-season' />,
    errorElement: <Error404 />,
  },
  {
    path: 'events/saudi-cup-season',
    element: <JCSAEventGroupPage slug='saudi-cup-season' />,
    errorElement: <Error404 />,
  },
  {
    path: 'events/saudi-games-24',
    element: <SaudiGamesPage />,
    errorElement: <Error404 />,
  },
  {
    path: 'spl',
    element: <SPLPage />,
    errorElement: <Error404 />,
  },
  {
    path: 'spl-teams',
    element: <SPLTeamsPage />,
    errorElement: <Error404 />,
  },
  // Must be last
  {
    path: ':slug',
    element: <PageBySlug />,
    errorElement: <Error404 />,
  },
];
