import { RouteObject } from 'react-router-dom';
import OrganizationDetailPage from '@/pages/organizations/slug';
import Error404 from '@/components/error/404';

export const organizationRoutes: RouteObject[] = [
  {
    path: 'organizations/:slug',
    errorElement: <Error404 />,
    element: <OrganizationDetailPage />,
  },
];
