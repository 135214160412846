export type AccessibilityFormValues = {
  type: 'event' | 'season';
  slug: string;
  first_name: string;
  last_name: string;
  email: string;
  dob: string;
  country: string;
  city: string;
  nationality: string;
  disability_type: string;
  disability_card: File;
  gender: string;
  mode: AccessibilityStep;
  direction: 1 | -1;
  ticket_type: string;
  success?: boolean;
  loading?: boolean;
};

export enum AccessibilityStep {
  INFO,
  DISABILITY,
  TICKET,
}
