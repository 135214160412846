import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button } from '@wbk/ui';
import { ONE_LINK_URL } from '@/constants/apps';

type Props = Parameters<typeof Button>[0] & {
  children?: string | React.ReactNode;
};

const FreeStreamButton = ({ children, ...props }: Props) => {
  const { t } = useTranslation(['common', 'event']);

  return (
    <Button
      as={Link}
      to={ONE_LINK_URL}
      theme='secondary'
      className='min-h-12 w-full text-white'
      {...props}
    >
      {children || (
        <>
          <img alt={t('common:share')} src='/icons/common/live-tv.svg' width={22} height={22} />
          {t('event:watch_free_stream_app')}
        </>
      )}
    </Button>
  );
};

export default FreeStreamButton;
