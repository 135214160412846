import { ContentfulLocation } from '@wbk/contentful/api';
import { Button, Typography } from '@wbk/ui';
import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';

type Props = {
  location?: ContentfulLocation;
  isOnline: boolean;
  className?: string;
};

const EventLocation = ({ location, isOnline, className }: Props) => {
  const { t } = useTranslation('event');
  const title = location?.seactionHeader || t('event:location');
  const address = location?.address;

  if (!address) {
    return null;
  }

  return (
    <div className={twMerge('flex items-center gap-1.5 text-sm', className)}>
      <img
        src={isOnline ? '/icons/common/live-tv.svg' : '/icons/common/location-marker.svg'}
        className='mt-px h-8 w-8 shrink-0'
        width={32}
        height={32}
        alt=''
      />
      <div>
        <Typography variant='label-M'>{title}</Typography>
        {location.location.lat && location.location.lat ? (
          <Button
            as='a'
            target='_blank'
            rel='noreferrer'
            href={`https://www.google.com/maps/search/?api=1&query=${location.location.lat},${location.location.lon}`}
            className='px-px py-0 text-start text-sm underline'
            theme='white'
            shape='text'
          >
            <Typography variant='body-S' color='text-secondary'>
              {address}
            </Typography>
          </Button>
        ) : (
          <Typography variant='label-M'>{address}</Typography>
        )}
      </div>
    </div>
  );
};

export default EventLocation;
