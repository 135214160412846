import { useState } from 'react';
import { WebCard, WebSection } from '@wbk/contentful/api';
import { Button } from '@wbk/ui';
import { ContentfulMedia } from '@wbk/contentful';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import SearchProvider from '@/context/search/provider';
import HomeSearch from './HomeSearch';

interface Props {
  section: WebSection;
}

const SearchSection = ({ section }: Props) => {
  const items = section.contentCollection.items as WebCard[];
  const [selectedTab, setSelectedTab] = useState(items?.[0]?.href);
  const { t } = useTranslation(['home', 'flight_common']);
  if (!items.length) return null;
  const title = items.find((item) => item.href === selectedTab)?.title;

  return (
    <section className='space-y-6 py-12'>
      <h1 className='text-center text-xl font-semibold md:text-3xl'>
        {title || t('home:search_title.full')}
      </h1>
      <div className='flex items-center justify-center gap-4'>
        {items.map((item) => (
          <Button
            as={Link}
            href={item.href}
            key={item.href}
            aria-selected={selectedTab === item.href}
            phill
            theme='action'
            shape='outlined'
            className='aria-[selected=false]:border-text/20 aria-[selected=false]:text-text-700 h-9 border-2'
            onClick={() => setSelectedTab(item.href)}
          >
            {item.icon && <ContentfulMedia media={item.icon} />}
            {item.buttonLabel}
          </Button>
        ))}
      </div>
      {!selectedTab && (
        <SearchProvider>
          <HomeSearch as='div' hideTitle className='py-0' />
        </SearchProvider>
      )}
    </section>
  );
};

export default SearchSection;
