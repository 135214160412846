import { useMemo } from 'react';
import { getItem } from '@wbk/utils';
import { useTranslation } from 'react-i18next';
import {
  useGetEventsList,
  useGetExperiences,
  useGetRestaurants,
  useGetShows,
} from '@wbk/contentful/api';
import { useDeviceDetect } from '@wbk/hooks';
import { EventItem, EventListItem } from '@wbk/contentful';
import useLocalization from '@/context/localization/useLocalization';
import useFilters from '@/context/filters/useSearch';

const SEARCH_LIMIT = 4;

/**
 * This component should suggest some events/experiences/.. based on:
 * - Search history
 * - Selected filters -if any-
 */
const ExploreEmptySuggestion = () => {
  const { t } = useTranslation('common');
  const { locale } = useLocalization();
  const { country, city, zone, category } = useFilters();
  const { isMobile } = useDeviceDetect();

  const query = useMemo(() => {
    const prevHistory = getItem('search_history');
    const history = (prevHistory ? JSON.parse(prevHistory) : []) as string[];
    const suggestions = [] as Array<{ [k: string]: unknown }>;

    for (const his of history) {
      // take first 3 letters
      suggestions.push(
        { title_contains: his.slice(0, 3) },
        { description_contains: his.slice(0, 3) },
        { category: { title_contains: his.slice(0, 3) } },
        { location: { title_contains: his.slice(0, 3) } },
        { zone: { title_contains: his.slice(0, 3) } },
        {
          seo: {
            OR: [
              { title_contains: his.slice(0, 3) },
              { keywords_contains: his.slice(0, 3) },
              { description_contains: his.slice(0, 3) },
            ],
          },
        }
      );
    }

    // Check some filters
    if (country) {
      suggestions.push({ location: { countryCode: country } });
    }
    if (city) {
      suggestions.push({ location: { cityCode: city } });
    }
    if (zone?.length) {
      suggestions.push({ zone: { slug_in: zone } });
    }
    if (category?.length) {
      suggestions.push({ category: { slug_in: category } });
    }

    return {
      lang: locale,
      limit: SEARCH_LIMIT,
      where: { OR: suggestions },
    };
  }, [category, city, country, locale, zone]);

  const canFetch = query.where.OR.length > 0;

  const { data: events } = useGetEventsList(query, { enabled: canFetch });
  const { data: experiences } = useGetExperiences(query, { enabled: canFetch });
  const { data: shows } = useGetShows(query, { enabled: canFetch });
  const { data: restaurants } = useGetRestaurants(query, { enabled: canFetch });

  const merged = useMemo(() => {
    const eventsData = events?.items || [];
    const experiencesData = experiences?.items || [];
    const showsData = shows?.items || [];
    const restaurantsData = restaurants?.items || [];

    return eventsData.concat(experiencesData, showsData, restaurantsData);
  }, [events?.items, experiences?.items, restaurants?.items, shows?.items]);

  if (merged.length <= 0) {
    return null;
  }

  return (
    <div className='space-y-4'>
      <h4>{t('common:empty_search_suggestion')}</h4>
      <div className='xs:grid-cols-2 grid grid-cols-1 gap-4 md:grid-cols-3 xl:grid-cols-4'>
        {merged.map((event) =>
          isMobile ? (
            <EventListItem key={event.id} event={event} />
          ) : (
            <EventItem key={event.id} event={event} />
          )
        )}
      </div>
    </div>
  );
};

export default ExploreEmptySuggestion;
