import { RouteObject } from 'react-router-dom';
import Error404 from '@/components/error/404';
import SurveyPage from '@/pages/survey/slug';
import LinkCheckPage from '@/pages/survey/link-check';

export const surveyRoutes: RouteObject[] = [
  {
    path: 'survey/:slug',
    errorElement: <Error404 />,
    element: <SurveyPage />,
  },
  {
    path: 'survey/link-check',
    errorElement: <Error404 />,
    element: <LinkCheckPage />,
  },
];
