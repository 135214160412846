import { RouteObject } from 'react-router-dom';
import SearchPage from '@/pages/search';
import Error404 from '@/components/error/404';

export const searchRoutes: RouteObject[] = [
  {
    path: 'search',
    element: <SearchPage />,
    errorElement: <Error404 />,
  },
];
