import { useEffect, useState } from 'react';
import { readCookie } from '@wbk/utils';
import { HideOnQueue } from '@wbk/queue';
import { Link, useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button } from '@wbk/ui';
import UserMenu from './UserMenu';

const UserProfileButton = () => {
  const { lang } = useParams();
  const { t } = useTranslation('common');
  const { pathname } = useLocation();
  const [hasCookie, setHasCookie] = useState(false);

  useEffect(() => {
    const token = readCookie('token');
    if (token) {
      setHasCookie(true);
    }
  }, []);

  return (
    <HideOnQueue>
      {hasCookie ? (
        <UserMenu />
      ) : (
        <Button
          as={Link}
          to={`/${lang}/login?redirect=${pathname}`}
          theme='white'
          shape='outlined'
          data-testid='header_nav_login_button'
          data-location='header'
          className='w-full !px-1.5 !py-1.5 md:!px-2.5'
          role='menuitem'
        >
          <p className='hidden sm:block'>{t('common:auth.login_signup')}</p>
          <div className='block shrink-0 rounded-full md:hidden'>
            <img
              className='shrink-0'
              src='/icons/profile/user-large.svg'
              width={20}
              height={20}
              alt='user icon'
            />
          </div>
        </Button>
      )}
    </HideOnQueue>
  );
};

export default UserProfileButton;
