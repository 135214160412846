import { motion } from 'framer-motion';

const ResellTopHand = ({ className = '' }) => {
  return (
    <motion.svg
      width='74'
      height='77'
      viewBox='0 0 74 77'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
      variants={{
        visible: { y: '0%' },
        hidden: { y: '-100%' },
      }}
      transition={{ type: 'spring', damping: 18 }}
    >
      <g opacity='0.26'>
        <path
          d='M63.7785 -64.5548C56.9315 -69.027 55.5128 -72.7589 53.6005 -76.5834C53.6005 -76.5834 36.9148 -22.9484 30.3762 -10.118C24.2694 -0.0942078 22.2955 2.5274 18.3168 7.36967C16.2195 10.0221 17.8233 17.1776 20.1365 24.333C18.872 27.6331 15.4793 36.3307 13.9989 39.7233C12.1483 43.9179 11.1614 51.0117 7.24438 58.8457C3.3274 66.6796 0.0581055 71.5836 0.0581055 71.5836L35.0951 76.7034L36.5138 74.3902H40.6467L25.719 18.7814L22.8198 19.4907L23.6217 16.9308L30.808 18.5346C30.808 18.5346 29.3584 30.3781 31.98 31.3651C34.6016 32.3829 39.0121 28.25 44.5329 9.80621C44.5329 9.80621 52.5827 2.99004 46.4143 -11.5367C46.4143 -11.5367 50.177 -20.0492 65.1973 -44.0138C70.4097 -53.8834 70.6256 -60.0827 63.7785 -64.5548Z'
          fill='black'
        />
      </g>
      <path
        d='M59.1829 20.5703L64.1794 21.1563C64.1794 21.1563 48.6348 59.8327 40.6775 74.3595L28.1863 71.8921L39.0736 16.0056L57.5174 19.2132L59.1829 20.5703Z'
        fill='#BBF7D0'
      />
      <path
        d='M27.6003 16.0365L58.6277 23.1919C58.6277 23.1919 44.1627 61.7758 35.5576 75.3773L2.80298 70.2883C2.80298 70.2883 10.7603 59.5243 13.567 49.7164C16.3736 39.9085 23.3749 26.2145 23.3749 26.2145L27.6003 16.0365Z'
        fill='#BBF7D0'
      />
      <path
        d='M31.6713 72.2003L9.52644 68.5609L9.7115 68.345C9.7115 68.345 10.6985 67.173 9.24886 64.4897L9.21802 64.428L9.24886 64.3663C9.31055 64.243 15.88 52.9547 19.1493 43.8253C22.4186 34.696 28.4328 21.6188 28.4945 21.4954L28.5254 21.4029H28.6179C28.6487 21.4029 32.1339 21.2795 33.4293 19.5215L33.491 19.429L52.2123 23.5618V23.6852C52.2123 23.716 52.089 26.1218 54.834 27.8181L54.9573 27.8798L54.8956 28.0031C54.834 28.1265 48.8505 39.1064 46.3214 45.7375C43.8232 52.4303 36.2977 70.0722 36.236 70.2573L36.1743 70.3806L36.0509 70.3498C36.0201 70.3498 33.3676 69.8255 31.7021 72.1078L31.6713 72.2003ZM10.0816 68.345L31.5171 71.861C33.09 69.7946 35.4341 69.9488 35.9892 70.0414C36.6369 68.5301 43.6381 52.0294 46.013 45.645C48.3879 39.384 53.847 29.2368 54.5255 27.9723C52.089 26.3685 51.9039 24.3021 51.9039 23.7469L33.6144 19.7374C32.319 21.4029 29.2964 21.6188 28.7104 21.6496C28.1861 22.7908 22.5419 35.1586 19.396 43.887C16.2809 52.6462 10.1124 63.4102 9.52644 64.3972C10.7293 66.6487 10.3592 67.8824 10.0816 68.345Z'
        fill='#231F20'
      />
      <path
        d='M53.6004 -76.5834C53.6004 -76.5834 39.382 -22.5783 32.8434 -9.74787C26.7366 0.275917 24.7319 2.83584 20.7532 7.6781C18.4709 10.5773 20.7532 18.4421 23.3748 26.1835L27.6311 16.0364L34.8173 17.6402C34.8173 17.6402 33.3677 29.4837 35.9893 30.4706C38.611 31.4576 43.0214 27.3556 48.5422 8.9118C48.5422 8.9118 56.5921 2.09561 50.4236 -12.4312C50.4236 -12.4312 54.1864 -20.9437 69.2066 -44.9082C74.419 -54.8086 74.6349 -61.008 67.7879 -65.4801C60.91 -69.9214 55.5126 -72.7589 53.6004 -76.5834Z'
        fill='white'
      />
      <path
        d='M44.6563 18.7816C44.6563 18.7816 52.4902 20.4162 59.1831 20.5704C59.1831 20.5704 60.6635 18.5965 62.1748 16.8077C63.686 15.0188 62.0822 13.7851 61.0953 13.1682C61.0953 13.1682 63.0692 9.68305 59.8924 8.85031C56.7465 8.01756 52.4594 14.8646 51.8117 16.6226C51.8117 16.6226 49.1593 13.0757 45.8283 13.6617C42.4973 14.2477 44.6563 18.7816 44.6563 18.7816Z'
        fill='white'
      />
      <path
        d='M59.4299 20.7245C59.3374 20.7245 59.2448 20.6936 59.1523 20.6319C58.9364 20.4777 58.9056 20.2001 59.0598 19.9843C59.0598 19.9534 60.3243 18.3188 61.8048 16.4991C62.2057 16.0364 62.3599 15.5738 62.2982 15.1728C62.1749 14.4635 61.4038 13.9083 60.8178 13.5382C60.6019 13.4148 60.5402 13.1372 60.6636 12.9213C60.6636 12.9213 61.5272 11.3484 61.0645 10.2689C60.8486 9.80625 60.4168 9.46699 59.7383 9.28194C57.0242 8.57256 52.8605 14.9878 52.2128 16.7766C52.1511 16.9617 51.966 17.0851 51.781 17.0851C51.5651 17.0851 51.4109 16.9617 51.3492 16.8075C51.2875 16.6841 50.0229 13.569 47.0929 14.0625C46.8462 14.0934 46.5995 13.9391 46.5686 13.6924C46.5378 13.4457 46.692 13.1989 46.9387 13.1681C49.2827 12.7671 50.9174 14.2784 51.7501 15.6046C53.1072 13.0139 56.9008 7.61644 59.9851 8.44919C60.972 8.69593 61.6197 9.22025 61.9281 9.96047C62.3908 11.0708 61.959 12.397 61.6505 13.0447C62.3291 13.5073 63.0384 14.155 63.1927 15.0803C63.316 15.7588 63.0693 16.4682 62.4833 17.1468C61.0029 18.9356 59.7691 20.5703 59.7383 20.5703C59.7075 20.6628 59.5841 20.7245 59.4299 20.7245Z'
        fill='black'
      />
      <path
        d='M36.7604 31.0875C36.452 31.0875 36.1436 31.0258 35.8351 30.9024C33.0285 29.8229 34.108 19.6141 34.3856 17.5785C34.3856 17.5168 35.0332 9.09685 34.6014 6.35188C34.5706 6.10513 34.7248 5.85839 35.0024 5.82755C35.2492 5.79671 35.4959 5.95092 35.5267 6.2285C35.9585 9.09684 35.3417 17.3318 35.3108 17.671C34.7248 22.4516 34.6015 29.422 36.1744 30.0388C36.6371 30.2239 37.1922 30.1622 37.7474 29.8846C41.8803 27.8799 46.0132 15.7896 48.1104 8.75758C48.1413 8.66505 48.1721 8.60336 48.2646 8.54168C48.2955 8.51083 50.2077 6.84534 51.4106 3.45268C52.49 0.337601 53.1377 -4.93645 50.0227 -12.2769C49.961 -12.4003 49.961 -12.5237 50.0227 -12.6471C50.0535 -12.7396 53.9705 -21.4988 68.8365 -45.155C74.2339 -55.3946 73.8638 -60.9771 67.572 -65.11C66.2458 -65.9736 65.0121 -66.7446 63.8092 -67.5157C58.7819 -70.6925 54.8341 -73.1907 53.2303 -76.3983C53.1069 -76.6142 53.1994 -76.8918 53.4462 -77.0152C53.6621 -77.1385 53.9396 -77.046 54.063 -76.7993C55.5435 -73.8384 59.4296 -71.371 64.3027 -68.2868C65.5056 -67.5157 66.7701 -66.7446 68.0655 -65.8811C74.7891 -61.5014 75.2518 -55.3638 69.6384 -44.6923C69.6384 -44.6923 69.6384 -44.6615 69.6076 -44.6615C55.9136 -22.8559 51.5648 -13.7574 50.9171 -12.4312C52.6751 -8.20575 54.2789 -2.03726 52.2125 3.82279C51.1021 6.99957 49.375 8.72673 48.9432 9.15853C45.2112 21.6497 41.4485 29.0827 38.1483 30.7174C37.6548 30.9641 37.1922 31.0875 36.7604 31.0875Z'
        fill='black'
      />
      <path
        d='M27.5694 15.2964C27.5385 15.2964 27.5077 15.2964 27.4768 15.2964C24.516 14.4945 23.6215 12.2121 23.5598 12.1196C23.4982 11.9654 23.5907 11.7803 23.7449 11.7187C23.8991 11.657 24.0842 11.7495 24.1459 11.9037C24.1767 11.9962 25.0094 14.001 27.6311 14.7104C27.7853 14.7412 27.8778 14.9263 27.847 15.0805C27.8161 15.2039 27.6927 15.2964 27.5694 15.2964Z'
        fill='black'
      />
      <path
        d='M51.0407 19.5526C50.979 19.5526 50.8865 19.5218 50.8248 19.491C50.6089 19.3676 50.5164 19.09 50.6397 18.8741C50.6706 18.8124 51.6575 17.0236 53.4464 15.3889C55.8213 13.1683 58.5045 12.2738 61.1878 12.7673C61.4346 12.7982 61.6196 13.0449 61.558 13.2916C61.5271 13.5384 61.2804 13.7234 61.0336 13.6617C55.2353 12.5823 51.5033 19.2442 51.4725 19.3059C51.3491 19.4601 51.1949 19.5526 51.0407 19.5526Z'
        fill='black'
      />
      <path
        d='M49.0362 5.98201C49.0054 5.98201 48.9745 5.982 48.9128 5.95116C48.7586 5.88948 48.6661 5.70443 48.7278 5.55021C51.812 -2.19123 47.8334 -9.2233 47.8025 -9.28499C47.71 -9.4392 47.7717 -9.62425 47.9259 -9.71678C48.0801 -9.80931 48.2651 -9.74762 48.3577 -9.59341C48.3885 -9.53173 52.5214 -2.25292 49.3446 5.79695C49.2829 5.92032 49.1596 5.98201 49.0362 5.98201Z'
        fill='black'
      />
      <path
        d='M31.4864 54.7745C30.4686 54.7745 29.42 54.5895 28.433 54.1577C26.2432 53.2324 24.6085 51.4127 23.8066 49.0378C23.0047 46.6629 23.1281 43.9797 24.1767 41.5123C26.3049 36.4233 31.7023 33.8017 36.1744 35.683C40.6466 37.5644 42.5897 43.2394 40.4307 48.3284C38.7961 52.3071 35.1566 54.7745 31.4864 54.7745ZM33.1519 35.3746C29.605 35.3746 26.089 37.7803 24.4852 41.6356C23.4674 44.0413 23.344 46.6321 24.1459 48.9453C24.917 51.2585 26.4899 53.0165 28.5872 53.8801C32.9052 55.6998 38.1175 53.1399 40.184 48.2051C42.2504 43.2703 40.3999 37.7803 36.0819 35.9606C35.1258 35.5905 34.1388 35.3746 33.1519 35.3746Z'
        fill='#231F20'
      />
      <path
        d='M38.1176 73.8657C38.1176 73.8657 44.1318 62.8242 50.2078 48.2049C51.4723 45.1823 59.8923 22.5748 59.8923 22.5748L44.8412 20.0149L58.5969 23.1917C58.5969 23.1917 45.273 59.9867 36.668 73.5882L38.1176 73.8657Z'
        fill='black'
      />
      <path
        d='M37.501 29.3294C37.2851 29.3294 37.0075 29.2986 36.7299 29.2369C35.6196 28.9902 35.0027 26.9546 35.3112 25.4741C35.5887 24.0245 36.6065 23.3152 38.0561 23.5619C39.4132 23.8086 40.246 24.3021 40.5544 25.0423C40.8011 25.7209 40.5852 26.5536 39.9375 27.3864C39.7833 27.6023 39.6291 27.7873 39.5057 27.9724C38.9506 28.7434 38.5188 29.3294 37.501 29.3294ZM37.501 24.1479C36.4523 24.1479 36.0514 24.919 35.928 25.5975C35.6504 26.9546 36.2673 28.4967 36.8841 28.6201C38.1178 28.8976 38.3646 28.4967 39.0431 27.6023C39.1665 27.4172 39.3207 27.2322 39.4749 27.0163C39.7833 26.6461 40.2151 25.9059 39.9992 25.2891C39.7833 24.7648 39.1048 24.3638 37.9636 24.1788C37.7786 24.1479 37.6243 24.1479 37.501 24.1479Z'
        fill='black'
      />
    </motion.svg>
  );
};

export default ResellTopHand;
