import { useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { BottomSheet, Button, Input, MobileInput, Modal } from '@wbk/ui';
import { useDeviceDetect, useWebsiteLocale } from '@wbk/hooks';
import { COUNTRIES, Country, EMAIL_PATTERN } from '@wbk/utils';
import { useRegisterInterest } from '@wbk/api/hooks';
import { twMerge } from 'tailwind-merge';
import { AnimatedCheck } from '@wbk/svg';
import { WithGrecaptcha } from '@wbk/api/grecaptcha';
import { useTranslation } from 'react-i18next';
import { useAnalytics } from '@wbk/analytics';

type Props = Parameters<typeof Button>[0] & {
  event: {
    id: string;
    slug: string;
  };
  className?: string;
  type: 'event' | 'experience';
};

type FormValues = {
  name: string;
  country: Country;
  phone: string;
  email: string;
};

const NotifyButton = ({ event, className, type, ...props }: Props) => {
  const { isMobile } = useDeviceDetect();

  return isMobile ? (
    <BottomSheet
      sectionClassName='px-4 pb-8'
      render={({ open }) => <ActionButton onClick={open} className={className} {...props} />}
      body={({ close }) => (
        <Form eventId={event.id} slug={event.slug} type={type} onClose={close} />
      )}
    />
  ) : (
    <Modal
      render={({ open }) => <ActionButton onClick={open} className={className} {...props} />}
      className='overflow-y-auto p-4'
      body={({ close }) => (
        <Form eventId={event.id} slug={event.slug} type={type} onClose={close} />
      )}
    />
  );
};

const Form = ({
  eventId,
  slug,
  onClose,
  type,
}: {
  eventId: string;
  slug: string;
  type: Props['type'];
  onClose: () => void;
}) => {
  const [success, setSuccess] = useState(false);
  const { t } = useTranslation(['common', 'event']);
  const { shortLang } = useWebsiteLocale();
  const {
    control,
    register,
    setValue,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<FormValues>({
    defaultValues: {
      name: '',
      country: COUNTRIES[0],
      phone: '',
      email: '',
    },
  });
  const { registerInterestEvent } = useAnalytics();
  const { mutateAsync: registerInterest, isPending, error } = useRegisterInterest();
  const country = watch('country');

  const onSubmit = async (data: FormValues) => {
    const values = {
      name: data.name,
      email: data.email,
      country_code: data.country.dial_code,
      phone: data.phone,
      venue_id: eventId,
      slug,
      type,
      lang: shortLang,
    };
    try {
      await registerInterest(values);
      registerInterestEvent(values);
      setSuccess(true);
    } catch (err) {
      //
    }
  };

  const selectedCountry = useMemo(() => {
    return COUNTRIES.find((c) => c.code === country.code);
  }, [country.code]);

  return (
    <WithGrecaptcha>
      <form onSubmit={handleSubmit(onSubmit)} className='space-y-4'>
        {success ? (
          <div>
            <AnimatedCheck className='text-success mx-auto h-40 w-40' />
            <div className='text-center'>
              <h2 className='pb-1 text-2xl font-semibold'>{t('event:notify.success')}</h2>
              <p className='text-text-secondary text-sm'>{t('event:notify.notify_success')}</p>
            </div>

            <Button
              type='button'
              shape='outlined'
              theme='white'
              className='mt-6 w-full text-xl font-bold'
              onClick={onClose}
            >
              {t('common:back')}
            </Button>
          </div>
        ) : (
          <>
            <div>
              <h2 className='pb-1 text-2xl font-semibold'>
                {t('event:notify.experience_is_offline')}
              </h2>
              <p className='text-text-secondary text-sm'>
                {t('event:notify.experience_is_offline_desc')}
              </p>
            </div>

            <Input
              label={<span className='font-normal'>{t('event:notify.name')}</span>}
              id='name'
              error={errors.name?.message || error?.message}
              {...register('name', {
                required: t('common:validation.required'),
              })}
              onChange={(value) => {
                setValue('name', value);
              }}
            />

            <Controller
              control={control}
              name='email'
              rules={{
                required: t('common:validation.required'),
                pattern: {
                  value: EMAIL_PATTERN,
                  message: t('common:validation.invalid_email'),
                },
              }}
              defaultValue=''
              render={({ field: { onChange, ...props } }) => (
                <Input
                  {...props}
                  type='email'
                  label={<span className='font-normal'>{t('event:notify.email')}</span>}
                  placeholder='you@email.com'
                  onChange={(value) => {
                    onChange(value);
                  }}
                  error={errors.email?.message}
                />
              )}
            />

            <Controller
              name='phone'
              control={control}
              rules={{
                required: t('common:validation.required'),
                validate: (value) => {
                  const hasValidation = selectedCountry?.mobile_starts_with?.length;
                  if (hasValidation) {
                    const validStart = selectedCountry?.mobile_starts_with?.some((start) =>
                      value.startsWith(start)
                    );
                    return validStart || t('common:validation.invalid_mobile');
                  }

                  return true;
                },
              }}
              render={({ field: { value, ...props } }) => (
                <MobileInput
                  {...props}
                  label={t('event:notify.mobile')}
                  country={country}
                  mobile={value}
                  portal={false}
                  onChange={(name, value) => {
                    if (name === 'country') {
                      setValue('country', value as FormValues['country']);
                    } else {
                      setValue('phone', value as FormValues['phone']);
                    }
                  }}
                  error={errors?.phone?.message || errors?.country?.message}
                  placeholder={
                    selectedCountry
                      ? `${selectedCountry?.mobile_starts_with?.[0] || ''}${'x'.repeat(
                          (selectedCountry.phone_number_lengths?.[0] || 7) - 1
                        )}`
                      : ''
                  }
                />
              )}
            />

            <div className='pt-4'>
              <Button className='w-full text-xl font-bold' type='submit' loading={isPending}>
                {t('common:submit')}
              </Button>
            </div>
          </>
        )}
      </form>
    </WithGrecaptcha>
  );
};

const ActionButton = ({ className, onClick, ...props }: Parameters<typeof Button>[0]) => {
  const { t } = useTranslation(['event']);
  return (
    <Button
      onClick={onClick}
      className={twMerge(
        'mx-auto block min-h-[50px] w-full gap-2 text-lg font-bold capitalize leading-none lg:mx-0',
        className
      )}
      {...props}
    >
      {t('event:notify.notify_me')}
    </Button>
  );
};

export default NotifyButton;
