import { Button, Input, Select, Typography } from '@wbk/ui';
import { COUNTRIES, sortedCities } from '@wbk/utils';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { AdvertiseFormValues } from './types';

const BOOKING_TYPE_OPTIONS: AdvertiseFormValues['booking_type'][] = [
  'experience',
  'event',
  'restaurant',
  'conference',
  'marketing_services',
  'sponsorship',
  'white_label_solution',
];

const BookingDetails = () => {
  const {
    control,
    watch,
    setValue,
    register,
    handleSubmit,
    formState: { errors },
  } = useFormContext<AdvertiseFormValues>();

  const { t } = useTranslation(['common', 'advertise']);
  const { lang } = useParams();
  const country = watch('country');

  const onSubmit = async () => {
    setValue('mode', 'additional_info');
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className='w-full max-w-lg space-y-6'>
      <Typography variant='heading-L' as='h2'>
        2. {t('advertise:booking_details.title')}
      </Typography>
      <Controller
        name='booking_type'
        control={control}
        rules={{ required: t('common:validation.required') }}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <Select
            value={value}
            id='booking_type'
            autoComplete='off'
            placeholder={t('advertise:booking_details.booking_type_placeholder')}
            onChange={(selected) => onChange(selected?.value || '')}
            label={
              <span className='font-normal'>{t('advertise:booking_details.booking_type')}</span>
            }
            options={BOOKING_TYPE_OPTIONS.map((option) => {
              return {
                id: option,
                value: option,
                text: t(`advertise:booking_details.${option}`),
              };
            })}
            error={error?.message}
          />
        )}
      />
      <Controller
        name='estimated_time_to_go_live'
        control={control}
        rules={{
          required: t('common:validation.required'),
        }}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <Input
            value={value}
            type='date'
            label={
              <span className='font-normal'>
                {t('advertise:booking_details.estimated_date_to_go_live')}
              </span>
            }
            id='estimated_time_to_go_live'
            error={error?.message}
            onChange={onChange}
            min={new Date().toISOString().split('T')[0]}
          />
        )}
      />

      <Controller
        rules={{ required: t('flight_common:validation.nationality.empty') }}
        control={control}
        name='country'
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <Select
            id='country'
            error={error?.message}
            autoComplete='off'
            onChange={(selected) => {
              onChange(selected?.value || '');
            }}
            value={value}
            label={t('advertise:booking_details.country')}
            portal={false}
            placeholder={t('advertise:booking_details.country_placeholder')}
            options={COUNTRIES.map((c) => ({
              id: c.code,
              value: c.code,
              text: c[`name_${lang}` as 'name'] || c.name,
              icon: (
                <img
                  className='shrink-0'
                  src={`https://wbk-assets.s3.me-south-1.amazonaws.com/flags/w80/${c.code.toLowerCase()}.png`}
                  width={20}
                  height={14}
                  alt={c.name}
                />
              ),
            }))}
          />
        )}
      />

      {country === 'SA' && (
        <Controller
          rules={{ required: t('common:validation.required') }}
          control={control}
          name='city'
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <Select
              id='city'
              error={error?.message}
              autoComplete='off'
              onChange={(selected) => {
                onChange(selected?.value || '');
              }}
              value={value}
              label={t('advertise:booking_details.city')}
              portal={false}
              placeholder={t('advertise:booking_details.city_placeholder')}
              options={sortedCities(lang).map((c) => ({
                id: c.id,
                value: c.value,
                text: c.text,
              }))}
            />
          )}
        />
      )}

      <Controller
        name='estimated_number_of_guests'
        control={control}
        rules={{ required: t('common:validation.required') }}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <Input
            value={value}
            type='number'
            min={1}
            label={
              <span className='font-normal'>
                {t('advertise:booking_details.estimated_number_of_guests')}
              </span>
            }
            id='estimated_number_of_guests'
            error={error?.message}
            onChange={onChange}
          />
        )}
      />

      <fieldset>
        <label className='block pb-1 font-normal' htmlFor='message'>
          {t('advertise:booking_details.brief_description')}
        </label>
        <textarea
          id='brief_description'
          placeholder={t('advertise:booking_details.brief_description_placeholder')}
          className='border-input-border bg-input hover:bg-input-hover focus:bg-input-hover focus:border-input-border placeholder:text-text-secondary block max-h-[300px] min-h-[120px] w-full rounded-md border p-2 focus:outline-none focus:ring-0'
          {...register('brief_description', {
            required: t('common:validation.required'),
            minLength: {
              value: 20,
              message: t('common:validation.min_length', { min: 20 }),
            },
          })}
          onChange={(e) => setValue('brief_description', e.target.value)}
        />
        {(errors?.brief_description?.message || errors?.brief_description?.message) && (
          <div className='text-error my-1 min-h-[16px] text-xs'>
            <p>{errors?.brief_description?.message || errors?.brief_description?.message}</p>
          </div>
        )}
      </fieldset>

      <div className='flex flex-wrap items-center justify-between gap-2'>
        <Button
          type='button'
          shape='outlined'
          theme='action'
          onClick={() => setValue('mode', 'contact_info')}
        >
          {t('common:back')}
        </Button>
        <Button theme='action' type='submit'>
          {t('common:next')}: {t('advertise:additional_info.title')}
        </Button>
      </div>
    </form>
  );
};

export default BookingDetails;
