import { ContentfulEvent, WebSection } from '@wbk/contentful/api';

export const resolveContentfulEventType = (
  type: WebSection['contentCollection']['items'][0]['__typename']
) => {
  return type === 'Package'
    ? 'packages'
    : type === 'Restaurant'
      ? 'restaurants'
      : type === 'Shows'
        ? 'shows'
        : type === 'Experience'
          ? 'experiences'
          : type === 'Auction'
            ? 'auctions'
            : 'events';
};

export const parseContentfulToAnalyticsEvent = (contentfulEvent?: ContentfulEvent) => {
  return {
    _id: contentfulEvent?.id || '',
    slug: contentfulEvent?.slug || '',
    poster: contentfulEvent?.image11?.url || '',
    title: contentfulEvent?.title || '',
    startingPrice: contentfulEvent?.startingPrice,
    currencyCode: contentfulEvent?.currencyCode,
    category: contentfulEvent?.category?.id || 'N/A',
    zone: contentfulEvent?.zone?.slug || 'N/A',
  };
};
