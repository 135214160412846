import { useFormContext } from 'react-hook-form';
import { Button } from '@wbk/ui';
import { useTranslation } from 'react-i18next';
import { AccessibilityFormValues, AccessibilityStep } from './types';

type Props = {
  onClose: () => void;
};

const AccessibilityFormActions = ({ onClose }: Props) => {
  const { t } = useTranslation(['common']);
  const { setValue, watch } = useFormContext<AccessibilityFormValues>();
  const mode = watch('mode');
  const loading = watch('loading');
  const success = watch('success');

  const handleBack = () => {
    setValue('direction', -1);
    if (mode === AccessibilityStep.TICKET) {
      setValue('mode', AccessibilityStep.DISABILITY);
    } else {
      setValue('mode', AccessibilityStep.INFO);
    }
  };

  const handleNext = () => {
    setValue('direction', 1);
  };

  if (success) {
    return (
      <div className='border-paper bg-body rounded-b-md border-t p-4 text-center'>
        <Button theme='white' className='px-10 font-semibold' onClick={onClose}>
          {t('common:back')}
        </Button>
      </div>
    );
  }

  return (
    <div className='border-paper bg-body flex items-center justify-between gap-4 rounded-b-md border-t p-4'>
      <div>
        {mode !== AccessibilityStep.INFO && (
          <Button theme='white' shape='outlined' onClick={handleBack} disabled={loading}>
            {t('common:back')}
          </Button>
        )}
      </div>

      <div className='flex items-center justify-between gap-2'>
        <Button theme='white' shape='text' onClick={onClose} disabled={loading}>
          {t('common:cancel')}
        </Button>
        <Button
          type='submit'
          form={`accessibility_step_${mode}`}
          className='px-6'
          onClick={handleNext}
          loading={loading}
        >
          {t('common:next')}
        </Button>
      </div>
    </div>
  );
};

export default AccessibilityFormActions;
