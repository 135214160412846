import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import { Button } from '@wbk/ui';
import { Restaurant } from '@wbk/contentful/api';

type Props = {
  data: Restaurant;
};

const ZoneEntry = ({ data }: Props) => {
  const { t } = useTranslation(['event']);
  const { lang } = useParams<{ lang: string }>();

  if (!data.zone) return null;

  return (
    <div className='border-paper border-t py-8'>
      <h2 className='mb-3 text-xl font-semibold md:text-2xl'>{t('event:zone_entry_label')}</h2>

      <div className='flex flex-col items-start justify-between gap-3 lg:flex-row lg:items-center lg:gap-5'>
        <div className='text-text-secondary'>
          <p className='mb-3 font-medium'>
            {data.zoneEntryIncluded
              ? t('event:zone_entry_included')
              : t('event:zone_entry_not_included')}
          </p>
          <p className='text-sm'>
            {data.zoneEntryIncluded
              ? t('event:zone_entry_description')
              : t('event:require_zone_ticket', { zone: data.zone.title })}
          </p>
        </div>

        {!data.zoneEntryIncluded && (
          <Button
            className='border-input-border h-8 shrink-0 text-sm'
            shape='outlined'
            theme='white'
            as={Link}
            to={`/${lang}/zones/${data.zone.slug}`}
            arrow
          >
            {t('event:book_zone_tickets')}
          </Button>
        )}
      </div>
    </div>
  );
};

export default ZoneEntry;
