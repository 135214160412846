import { useTranslation } from 'react-i18next';
import { ContentfulEvent } from '@wbk/contentful/api';
import { twMerge } from 'tailwind-merge';
import { ImageWithFallback } from '@wbk/ui';

type Props = {
  event: ContentfulEvent;
  className?: string;
};

const StreamIncludedBar = ({ event, className }: Props) => {
  const { t } = useTranslation('event');

  return (
    <div className={twMerge('flex items-center gap-1 text-sm', className)}>
      <ImageWithFallback
        className='p-0.5 rtl:rotate-180'
        src={event.buttonIcon?.url || '/icons/common/play.svg'}
        width={20}
        height={20}
        alt={t('event:stream.available')}
      />
      <p className='text-sm'>{t('event:stream.available')}</p>
    </div>
  );
};

export default StreamIncludedBar;
