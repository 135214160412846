import React from 'react';
import { RouteObject } from 'react-router-dom';
import Error404 from '@/components/error/404';
import AppLayout from '@/App';
const PlanBookingPage = React.lazy(() => import('@/pages/subscriptions/book'));
const PlanUpgradePage = React.lazy(() => import('@/pages/subscriptions/upgrade'));
const PlansPage = React.lazy(() => import('@/pages/subscriptions'));
const ManageSubscriptionsPage = React.lazy(() => import('@/pages/subscriptions/manage'));

export const subscriptionRoutes: RouteObject[] = [
  {
    path: 'subscriptions',
    errorElement: <Error404 />,
    element: <AppLayout />,
    children: [
      {
        path: '',
        errorElement: <Error404 />,
        element: <PlansPage />,
      },
      {
        path: 'manage/:id',
        element: <ManageSubscriptionsPage />,
        errorElement: <Error404 />,
      },
    ],
  },
  {
    path: 'subscriptions',
    errorElement: <Error404 />,
    element: <AppLayout type='booking' />,
    children: [
      {
        path: 'book/:id',
        element: <PlanBookingPage />,
      },
      {
        path: 'upgrade/:id',
        element: <PlanUpgradePage />,
      },
    ],
  },
];
