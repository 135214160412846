import { Link, useLocation, useNavigate } from 'react-router-dom';
import { AnalyticsProvider } from '@wbk/analytics';
import { AuthProvider } from '@wbk/auth';
import { Trans, useTranslation } from 'react-i18next';
import { HyExperienceProvider } from '@hy/experiences';
import { WbkTicketingProvider } from '@wbk/ticketing';
import { WbkPackagesProvider } from '@wbk/packages';
import { WbkQueueProvider } from '@wbk/queue';
import { SmcAdsProvider } from '@smc/ads';
import { UiProvider } from '@wbk/ui';
import { SubscriptionProvider } from '@wbk/subscription';
import { CurrencyProvider } from '@wbk/currency';
import { ContentfulProvider } from '@wbk/contentful';
import { FlightsProvider } from '@wbk/flights';
import { WbkAuctionProvider } from '@wbk/auction';
import { POSTER_1X1_FADED, POSTER_3X1_FADED } from '@/constants/fallbackLogo';
import { SUBSCRIPTIONS_SLUG } from '@/constants/subscriptions';
import useLocalization from './localization/useLocalization';
import { gtmTracker, wbkTracker } from '../../trackers.config';

type Props = {
  children: React.ReactNode;
};

const AppProviders = ({ children }: Props) => {
  const { locale, detectedCurrency } = useLocalization();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const commonConfig = {
    locale,
    i18n: { useTranslation, Trans },
    router: {
      Link,
      push: navigate,
      replace: (u: string) => navigate(u, { replace: true }),
    },
  };

  return (
    <UiProvider
      {...commonConfig}
      imgFallbacks={{ '1x1': POSTER_1X1_FADED, '3x1': POSTER_3X1_FADED }}
    >
      <AnalyticsProvider trackers={[gtmTracker, wbkTracker]} currentLocation={pathname}>
        <CurrencyProvider {...commonConfig} detectedCurrency={detectedCurrency}>
          <AuthProvider
            {...commonConfig}
            autoInit={false}
            appSource={process.env.VITE_PUBLIC_APP_SOURCE}
          >
            <SubscriptionProvider
              {...commonConfig}
              slug={SUBSCRIPTIONS_SLUG}
              bookingSource={process.env.VITE_PUBLIC_APP_SOURCE!}
            >
              <WbkQueueProvider {...commonConfig}>
                <WbkTicketingProvider
                  {...commonConfig}
                  bookingSource={process.env.VITE_PUBLIC_APP_SOURCE!}
                  resell={{ domain: process.env.VITE_PUBLIC_RESELL_DOMAIN }}
                  seatsIO={{
                    workspace: process.env.VITE_PUBLIC_SEATIO_WORKSPACE_KEY,
                  }}
                >
                  <WbkPackagesProvider
                    {...commonConfig}
                    bookingSource={process.env.VITE_PUBLIC_APP_SOURCE!}
                  >
                    <WbkAuctionProvider
                      {...commonConfig}
                      endpoint={process.env.VITE_PUBLIC_SOCKET_HTTP_URL!}
                      socketEndpoint={process.env.VITE_PUBLIC_SOCKET_URL!}
                    >
                      <FlightsProvider
                        bookingSource={process.env.VITE_PUBLIC_APP_SOURCE!}
                        {...commonConfig}
                      >
                        <SmcAdsProvider
                          env={
                            process.env.VITE_PUBLIC_ENV === 'production'
                              ? 'production'
                              : 'development'
                          }
                        >
                          <HyExperienceProvider {...commonConfig}>
                            <ContentfulProvider {...commonConfig}>{children}</ContentfulProvider>
                          </HyExperienceProvider>
                        </SmcAdsProvider>
                      </FlightsProvider>
                    </WbkAuctionProvider>
                  </WbkPackagesProvider>
                </WbkTicketingProvider>
              </WbkQueueProvider>
            </SubscriptionProvider>
          </AuthProvider>
        </CurrencyProvider>
      </AnalyticsProvider>
    </UiProvider>
  );
};

export default AppProviders;
