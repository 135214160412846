import { RouteObject } from 'react-router-dom';
import EventDetailPage from '@/pages/events/slug';
import ExperienceDetailPage from '@/pages/experiences/slug';
import RetaurantsDetailPage from '@/pages/restaurants/slug';
import ShowsDetailPage from '@/pages/shows/slug';
import PackagesDetailPage from '@/pages/packages';
import Error404 from '@/components/error/404';
import EventGroupPage from '@/pages/group/slug';
import SeasonDetailPage from '@/pages/seasons/slug';

export const eventDetailRoutes: RouteObject[] = [
  {
    path: 'event/:slug',
    errorElement: <Error404 />,
    element: <EventDetailPage />,
  },
  {
    path: 'events/:slug',
    errorElement: <Error404 />,
    element: <EventDetailPage />,
  },
  {
    path: 'season/:slug',
    errorElement: <Error404 />,
    element: <SeasonDetailPage />,
  },
  {
    path: 'seasons/:slug',
    errorElement: <Error404 />,
    element: <EventDetailPage />,
  },
  {
    path: 'experience/:slug',
    errorElement: <Error404 />,
    element: <ExperienceDetailPage />,
  },
  {
    path: 'experiences/:slug',
    errorElement: <Error404 />,
    element: <ExperienceDetailPage />,
  },
  {
    path: 'restaurant/:slug',
    errorElement: <Error404 />,
    element: <RetaurantsDetailPage />,
  },
  {
    path: 'restaurants/:slug',
    errorElement: <Error404 />,
    element: <RetaurantsDetailPage />,
  },
  {
    path: 'show/:slug',
    errorElement: <Error404 />,
    element: <ShowsDetailPage />,
  },
  {
    path: 'shows/:slug',
    errorElement: <Error404 />,
    element: <ShowsDetailPage />,
  },
  {
    path: 'package/:slug',
    errorElement: <Error404 />,
    element: <PackagesDetailPage />,
  },
  {
    path: 'packages/:slug',
    errorElement: <Error404 />,
    element: <PackagesDetailPage />,
  },
  {
    path: 'group/:slug',
    errorElement: <Error404 />,
    element: <EventGroupPage />,
  },
];
