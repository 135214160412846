import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import { useParams } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import { Button } from '@wbk/ui';
import { UpRightIcon } from '@wbk/svg';
import ResellTopHand from '../svg/ResellTopHand';
import ResellBottomHand from '../svg/ResellBottomHand';

type Props = {
  slug?: string;
  className?: string;
  show?: boolean;
  mini?: boolean;
};

const ResellBanner = ({ slug, show, mini, className = '' }: Props) => {
  const { t } = useTranslation('event');
  const { lang } = useParams<{ lang: Language }>();

  if (!slug || !show) return null;

  if (mini) {
    return (
      <div className={twMerge('gap-S flex items-center rounded-md p-2', className)}>
        <img className='h-6 w-6 shrink-0' src='/icons/resell/ticket.svg' alt='' />
        <div className='flex grow flex-col items-start justify-between gap-3 sm:flex-row sm:items-center'>
          <div>
            <p className='text-lg font-semibold'>{t('event:resell.available_on_resell')}</p>
            <p className='text-text text-sm'>{t('event:resell.find_tickets')}</p>
          </div>
          <Button
            className='shrink-0 px-px text-sm underline'
            as='a'
            href={`${process.env.VITE_PUBLIC_RESELL_DOMAIN}/${lang}/events/${slug}`}
            target='_blank'
            rel='noopener noreferrer'
            theme='white'
            shape='text'
          >
            <span> {t('event:resell.open_resell')}</span>
            <UpRightIcon className='text-primary-300 h-5 w-5 shrink-0' />
          </Button>
        </div>
      </div>
    );
  }

  return (
    <motion.div
      className={twMerge(
        'mb-6 overflow-hidden rounded-lg border border-white/10 bg-cover',
        className
      )}
      initial={{ height: 0 }}
      animate={{ height: 'auto' }}
    >
      <div className='flex flex-col items-center justify-between gap-4 space-y-5 p-4 md:flex-row md:space-y-0'>
        <div className='-my-4 flex flex-col items-center gap-2 md:flex-row'>
          <motion.div
            className='flex overflow-hidden'
            initial='hidden'
            animate='hidden'
            whileInView='visible'
            viewport={{ once: false, amount: 0.7 }}
          >
            <ResellTopHand />
            <ResellBottomHand />
          </motion.div>
          <div className='space-y-0.5 text-center md:text-start'>
            <p className='text-sm font-semibold'>{t('event:resell.title')}</p>
            <p className='text-xs'>{t('event:resell.see_other')}</p>
          </div>
        </div>

        <div className='flex flex-col items-center justify-center space-y-2'>
          <Button
            as='a'
            href={`${process.env.VITE_PUBLIC_RESELL_DOMAIN}/${lang}/events/${slug}`}
            target='_blank'
            rel='noopener noreferrer'
            theme='white'
            shape='outlined'
            className='py-2 text-xs font-semibold'
          >
            resell.webook.com
            <img
              className='shrink-0 ltr:rotate-90'
              src='/icons/common/external-arrow.svg'
              width={14}
              height={14}
              alt=''
            />
          </Button>
          <p className='inline-flex items-start gap-1 text-[10px]'>
            <img className='mt-0.5' src='/icons/common/shield.svg' width={12} height={12} alt='' />
            <span> {t('event:resell.guarentee')}</span>
          </p>
        </div>
      </div>
    </motion.div>
  );
};

export default ResellBanner;
